<template id="pagetop">
  <div class="phoneDiv">

    <div id="admin-window" v-if="currentUser === null || !currentUser">
      <div class="container-sm">
        <div class="row" v-if="errorMessages && errorMessages.length">
          <div class="col-8">
            <div class="alert alert-danger">
              <ul>
                <li :key="error" v-for="error in errorMessages">{{ error }}</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row phoneLoginRow">
          <div class="col-15">
            <h1>Insight-AWP</h1>
          </div>
        </div>

        <hr class="phoneHr" />

        <div class="card-deck phoneLogin">
          <!-- <div class="card btn-sp1 max" style="display: none"></div> -->
          <div class="card btn-sp1 max">
            <div class="card-body text-center">
              <button
                class="btn btn-green btn-spl stretched-link"
                v-on:click="$emit('fetchUser')"
                href="#"
                role="button"
              >
                Log In
              </button>
            </div>
          </div>
          <!-- <div class="card btn-sp1 max" style="display: none"></div> -->
        </div>
      </div>
    </div>
    
    <div id="admin-window" v-else>
      <div class="container-sm" >
        <div class="row" v-if="errorMessages && errorMessages.length">
          <div class="col-8">
            <div class="alert alert-danger">
              <ul>
                <li :key="error" v-for="error in errorMessages">{{ error }}</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-15">
            <h1 v-if="currentUser && currentUser.userType === 'sightstream'">Sightstream</h1>
            <h1 v-else>Insight-AWP</h1>
          </div>
        </div>

        <hr />

        <div class="card-deck phoneButtons">
          <div
            class="card card-border btn-sp1 max"
            @click="$router.push({ name: 'emp-add-timesheet' })"
          >
            <div class="card-body text-center">
              <h4 class="card-title">Add Timesheet</h4>
              <p class="card-text">
                <i class="fas fa-business-time"></i>
              </p>
            </div>
          </div>
          <div
            class="card card-border btn-sp1 max"
            @click="$router.push({ name: 'emp-add-expense' })"
          >
            <div class="card-body text-center">
              <h4 class="card-title">Add Expense</h4>
              <p class="card-text">
                <i class="fas fa-money-bill-alt"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// External libraries and components imported
import { mapGetters } from "vuex";

export default {
  data () {
    return {
        errorMessages: {}
    }
  },
  // computed properties
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      currentUser: "getLoggedInUser"
    }),
    isAdmin() {
      return this.isLoggedIn && this.currentUser.isAdmin;
    },

    isLoggedIn() {
      return this.currentUser !== null;
    }
  },
  async created() {
    this.setLoading(true);
    this.checkIsLoggedIn()
    .catch((e) => console.error(e))
    .finally((e) => this.setLoading(false))
  }
};
</script>
<style scoped>
hr {
  margin: 1rem 0 !important;
}
.card-deck .card-border {
  border: 1px solid #b7b7b7;
}
.card-deck .card-border:hover {
  background: #f2f2f2;
  cursor: pointer;
}

.stretched-link{
  font-size: 30px;
}


@media only screen and (max-width: 800px) {
.phoneDiv{
display: flex;
min-width: 100vw;
justify-content: center;
align-content: center;
}

.text-center{
  width:70vw
}
.phoneLogin{
  display: flex;
  justify-content: center;
  align-content: center;
}

.stretched-link{
  min-width: 67vw;
  margin-left: 40%;
}



.phoneLoginRow{
  margin-left: 45vw;
}

.phoneHr{
  width: 152%;
}


}
</style>

