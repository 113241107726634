import { PublicClientApplication } from "@azure/msal-browser";
import axios from "axios";

const USER_STORAGE_KEY = "user";
const ACCESS_TOKEN_STORAGE_KEY = "access_token";

// State object
const state = {
  // Intiating property with PublicClientApplication() object using values from .env
  msalConfig: new PublicClientApplication({
    auth: {
      clientId: process.env.VUE_APP_CLIENT_ID,
      authority: process.env.VUE_APP_AUTHORITY,
      redirectUri: process.env.VUE_APP_REDIRECT_URI,
      postLogoutRedirectUri: process.env.VUE_APP_POST_LOGOUT_URL || "/"
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false
    }
  }),
  scopes: [`api://${process.env.VUE_APP_SCOPES}/default`],
  // accessToken received from msal auth
  accessToken: localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY),
  // user object received from msal auth
  user: JSON.parse(localStorage.getItem(USER_STORAGE_KEY)),
  reauthenticating: false
};

// Getter functions
const getters = {
  // Returns user state
  getLoggedInUser(state) {
    console.log(state);
    return state.user;
  },
  // Returns access token state or null, if not exists
  getAccessToken(state) {
    console.log(state);
    return state.accessToken === "null" ? null : state.accessToken || null;
  },
  // Returns the scopes of the app for authentication
  getScopes(state) {
    return state.scopes || [];
  }
};

// Mutations
const mutations = {
  setAccessToken(state, token) {
    state.accessToken = token;
    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token);
  },
  logOut(state) {
    // state.msalConfig.logout();
    window.localStorage.clear();
    state.user = null;
    localStorage.removeItem(USER_STORAGE_KEY);
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    location.reload();
  },
  setUser(state, user) {
    state.user = user;
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
  }
};

const actions = {
  /**
   *
   * @param {any} context
   * @param {any} adInfo the information from active directory in Azure
   */
  async storeCredentials({ commit, dispatch }, adInfo) {
    try {
      // Fetch user permissions from backend using the access token
      const res = await getUserPermissions(adInfo.accessToken);

      // Check if the user has insight-awp in their email
      const userEmail = adInfo.account.username;
      if (userEmail.includes("insight-awp") || userEmail.includes("sightstream")) {
        let userType = "insight-awp";

        if (userEmail.includes("sightstream")) {
              userType = "sightstream";
        }
        else {
          userType = "insight-awp";
        }

        // If the user has insight-awp or sightstream in their email, store the user data
        commit("setUser", { ...adInfo.account, isAdmin: res.IsAdmin, userType: userType });
        commit("setAccessToken", adInfo.accessToken);
        return;
      } else {
        // If the user's email doesn't meet the condition, log them out
        // commit("logOut");
        // dispatch("setMessage", {
        //   message,
        //   type
        // });
        let message = "User is not allowed to login.";
        let type = "danger";
        this.dispatch("setMessage", {
          message,
          type
        });
        // console.error("User is not allowed to login.");
      }
    } catch (error) {
      // commit("logOut");
      console.error("logged out", error);
      throw error;
    }
  },

  // automatically Reauthenication when user logs out; wip; not ready to be used.
  reAuthenticateOrLogout() {
    console.log("rhrhghk");
    const accounts = state.msalConfig.getAllAccounts();
    console.log({ accounts });
    if (!accounts.length) {
      return this.commit("logOut");
    }
    this.dispatch("acquireAccessToken", accounts[0]);
  },
  // This function is part of above reauthentication; wip;
  acquireAccessToken(context, account) {
    if (this.getters.reauthenticating) {
      return;
    }
    console.log("silent acquisition");
    this.getters.reauthenticating = true;
    this.dispatch("setLoading", true);
    var request = {
      scopes: state.scopes,
      account,
      forceRefresh: true
    };
    state.msalConfig
      .acquireTokenSilent(request)
      .then((response) => this.dispatch("handleTokenAcquisition", response))
      .catch(function (error) {
        console.error(
          "Silent token acquisition failed. Using interactive mode",
          error
        );
        state.msalConfig
          .acquireTokenPopup(request)
          .then((response) => this.dispatch("handleTokenAcquisition", response))
          .catch(function (error) {
            console.error(
              "Interactive token acquisition failed, loggedOut",
              error
            );
            context.dispatch("setLoading", false);
            this.getters.reauthenticating = false;
            context.commit("logOut");
          });
        // console.log(`Access token acquired via interactive auth`);
      });
  },

  handleTokenAcquisition(context, response) {
    context.commit("setAccessToken", response.accessToken);
    context.dispatch("setLoading", false);
    this.getters.reauthenticating = false;
    context.dispatch("setForceUpdate", null);
  }
};

// returns Promise that resolves API call to fetch user permissions from backend
async function getUserPermissions(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/permissions`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res) => resolve(res.data))
      .catch((error) => reject(error));
  });
}

export default {
  state,
  getters,
  actions,
  mutations
};
