<template>
  <div>
    <b-container :style="dynamicStyle">
      <div class="d-flex justify-content-between">
        <h2 class="p-0 page_title">Profile</h2>
      </div>
      <hr class="my-2" />
      <br />
      <b-form>
        <div class="d-flex flex-wrap flex-wrapper">
          <div style="width: 31.4%">
            <label>First Name: </label>
            <div class="form-group">
              <b-form-input
                v-model="profile.first_name"
                placeholder="First Name"
                readonly
              ></b-form-input>
            </div>
          </div>
          <div style="width: 31.4%">
            <label>Middle Name: </label>
            <div class="form-group">
              <b-form-input
                v-model="profile.middle_name"
                placeholder="Middle Name"
                readonly
              ></b-form-input>
            </div>
          </div>
          <div style="width: 31.4%">
            <label>Last Name: </label>
            <div class="form-group">
              <b-form-input
                v-model="profile.last_name"
                placeholder="Last Name"
                readonly
              ></b-form-input>
            </div>
          </div>
          <div style="width: 31.4%">
            <label>Email: </label>
            <div class="form-group">
              <b-form-input
                v-model="profile.userName"
                type="email"
                placeholder="Email"
                readonly
              ></b-form-input>
            </div>
          </div>
          <div style="width: 31.4%">
            <label>Home Phone: </label>
            <div class="form-group">
              <b-form-input
                v-model="profile.home_phone"
                inputmode="tel"
                placeholder="Home Phone"
                readonly
              ></b-form-input>
            </div>
          </div>
          <div style="width: 31.4%">
            <label>Mobile Phone: </label>
            <div class="form-group">
              <b-form-input
                v-model="profile.mobile_phone"
                inputmode="tel"
                placeholder="Mobile Phone"
                readonly
              ></b-form-input>
            </div>
          </div>
          <div style="width: 31.4%">
            <label>Personal Email: </label>
            <div class="form-group">
              <b-form-input
                v-model="profile.personal_email"
                inputmode="email"
                placeholder="Personal Email"
                readonly
              ></b-form-input>
            </div>
          </div>
          <div style="width: 31.4%">
            <label>Business Name: </label>
            <div class="form-group">
              <b-form-input
                v-model="profile.business_name"
                placeholder="Business Name"
                readonly
              ></b-form-input>
            </div>
          </div>
          <div style="width: 31.4%">
            <label>Address: </label>
            <div class="form-group">
              <b-form-input
                v-model="profile.address"
                placeholder="Address"
                readonly
              ></b-form-input>
            </div>
          </div>
          <div style="width: 31.4%">
            <label>City: </label>
            <div class="form-group">
              <b-form-input v-model="profile.city" placeholder="City" readonly></b-form-input>
            </div>
          </div>
          <div style="width: 31.4%">
            <label>State/Province: </label>
            <div class="form-group">
              <b-form-input v-model="profile.state" placeholder="State" readonly></b-form-input>
            </div>
          </div>
          <div style="width: 31.4%">
            <label>Postal Code: </label>
            <div class="form-group">
              <b-form-input
                v-model="profile.postal_code"
                placeholder="Postal Code"
                readonly
              ></b-form-input>
            </div>
          </div>
          <div style="width: 31.4%">
            <label>Date of birth: </label>
            <div class="form-group">
              <b-form-input
                v-model="profile.dob"
                type="date"
                placeholder="Dob"
                readonly
              ></b-form-input>
            </div>
          </div>
          <div style="width: 31.4%">
            <label>Driver's License Number: </label>
            <div class="form-group">
              <b-form-input
                v-model="profile.driving_license_number"
                placeholder="Driving License Number"
                readonly
              ></b-form-input>
            </div>
          </div>
          <div style="width: 31.4%">
            <label>Issuing State/Province: </label>
            <div class="form-group">
              <b-form-input
                v-model="profile.driving_license_state"
                placeholder="Driving License State"
                readonly
              ></b-form-input>
            </div>
          </div>
        </div>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import StaffData from "@/core/StaffData";
import { extractError } from "@/utilities/ErrorUtilities";
import FormatUtilities from "@/utilities/FormatUtilities";
import { mapGetters } from 'vuex';


export default {
  data() {
    return {
      profile: new StaffData()
    };
  },
   computed: {
    ...mapGetters({
      currentUser: 'getLoggedInUser'
    }),
    dynamicStyle() {
      return {
        '--primary-color': this.currentUser && this.currentUser.userType === 'sightstream' ? '#7393B3' : '#72a02e'
      };
     }

  },
  async created() {
    try {
      this.setLoading(true);
      await this.loadProfile();
    } catch (error) {
      console.error(error);
    } finally {
      this.setLoading(false);
    }
  },

  methods: {
    addMonthsTo: FormatUtilities.addMonthsTo,

    async loadProfile() {
      try {
        const res = await this.$axios.get(`/profile`);
        if (res.data) {
          this.profile = StaffData.fromApi(res.data);
        }
      } catch (err) {
        console.error(err);
      }
    },

    setLoading(isLoading) {
      // Set loading state logic here
    }
  }
};
</script>

<style scoped>
label {
  font-size: 1.1rem !important;
}
h2.page_title {
  font-size: 1.5rem;
  color: var(--primary-color);
}
.flex-wrapper {
  gap: 0 2rem;
}
* {
  cursor: default;
}
</style>