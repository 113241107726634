import Vue from "vue";
import App from "./App.vue";
import VueForm from "vue-form";
import router from "./router";
//import store from './store'
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import store from "./store/index";
import axios from "axios";
import Vuelidate from "vuelidate";
import vueDebounce from "vue-debounce";
import moment from "moment";
import _ from "lodash";

// VCalendar plugins is being used for Calendar control
import VCalendar from "v-calendar";

// FilePond plugins is being used for File Upload while inserting expenses
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
);

Vue.component("file-pond", FilePond);

// Day.js parses, validates, manipulates, and displays dates and times
dayjs.extend(LocalizedFormat);

Object.defineProperties(Vue.prototype, {
  $date: {
    get() {
      return dayjs;
    }
  }
});

import "bootstrap-vue/dist/bootstrap-vue.css";
// require("@/assets/css/myERP.css");
const currentUser = store.getters.getLoggedInUser;
if (currentUser && currentUser.userType === "sightstream") {
  require("@/assets/css/SightstreamERP.css");
} else {
  require("@/assets/css/myERP.css");
}

//
// Register BaseComponents
const requireComponent = require.context(
  // The relative path of the components folder
  "./components",
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName);

  // Get PascalCase name of component
  const componentName = _.upperFirst(
    _.camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );
  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  );
});

// Axios request interceptor
axios.interceptors.request.use((config) => {
  if (!config.url.includes("rapidapi")) {
    config.baseURL = `${process.env.VUE_APP_API_ENDPOINT}/api`;
    config.timeout = 240000;
    config.headers.Accept = "application/json";
    config.headers["Cache-Control"] = "no-cache";
    config.headers.Pragma = "no-cache";
    config.headers.Expires = "0";
    config.withCredentials = true;
  }
  if (store.getters.getAccessToken) {
    config.headers.Authorization = `Bearer ${store.getters.getAccessToken}`;
  }
  return config;
});

// Axios response interceptor
axios.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status <= 299) {
      return Promise.resolve(response);
    }
    return Promise.reject(response);
  },
  (error) => {
    let url = error?.response?.config?.url || "";
    if (
      error.response &&
      error.response.status &&
      url.length &&
      !url.includes("permissions")
    ) {
      switch (error.response.status) {
        case 401:
          store.dispatch("reAuthenticateOrLogout");
          break;
        case 500:
          store.dispatch("setMessage", {
            message:
              error.response.data.message ||
              "your request could not be proccessed",
            type: "danger"
          });
          break;
        default:
          return Promise.reject(error.response);
      }
    } else {
      store.dispatch("setMessage", {
        message: error.message || "your request could not be proccessed",
        type: "danger"
      });
      return Promise.reject(error.response);
    }
  }
);

//
// Vue Use
//
Vue.use(vueDebounce);
Vue.use(VueForm);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(VCalendar, {
  componentPrefix: "vc"
});

// Mixins
Vue.mixin({
  methods: {
    makeToast(type = null, message = null, title = "Notification") {
      store.dispatch("setMessage", {
        message,
        type
      });
    },
    checkIsLoggedIn() {
      return new Promise(async (resolve, reject) => {
        if (!store.getters.getAccessToken) {
          return resolve();
        }
        try {
          let res = await axios.get(`/permissions`);
          resolve(res);
        } catch (error) {
          store.dispatch("reAuthenticateOrLogout");
          reject(error);
          if (router.currentRoute.name !== "app-home") {
            router.push({ name: "app-home" });
          }
        }
      });
    },
    setLoading(param = true) {
      store.dispatch("setLoading", param);
    }
  }
});

// moment.locale('en');

Vue.prototype.$axios = axios;
Vue.prototype.$_ = _;
Vue.config.productionTip = false;
Vue.prototype.$moment = moment;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
