<template>
    <div class="container">
        <h1 class="text-center mb-4">Welcome to Insight- AWP</h1>
        <p>
            This module is intended to make your onboarding go smoothly with
            {{ companyName }}.<br />Most of the information is straight forward but at any
            time if you need assistance, please reach out to <br> Janette Hess @
            <a href="tel:+1 281 686 5356">281 686 5356</a>
        </p>
        <div class="py-3" id="main-contacts">
            <a href="#main-contacts" class="link d-block">Main Company Contacts:</a>
            <table class="table table-hover table-striped">
                <tr>
                    <td>Geoff Ryan - CEO, Visas</td>
                    <td width="25%" class="text-right">
                        <a href="tel:+1 (713) 299-3897">+1 (713) 299-3897</a> <br>
                        <a href="mailto:Geoff.ryan@insight-awp.com">Geoff.ryan@insight-awp.com</a> 
                    </td>
                </tr>
                <tr>
                    <td>
                        Janette Hess - Business Administration - On boarding, Vehicles,
                        Visas, Payroll, Invoicing
                    </td>
                    <td class="text-right">
                        <a href="tel:+1 (281) 686 5356">+1 (281) 686 5356</a> <br>
                        <a href="mailto:Janette.Hess@insight-awp.com">Janette.Hess@insight-awp.com</a>
                        
                    </td>
                </tr>
                <tr>
                    <td>
                        Gregorio Labbozzetta - Director of Information Management
                    </td>
                    <td width="31%" class="text-right">
                        <a href="tel:+1 (281) 507 8121">+1 (281) 507 8121</a> <br>
                        <a href="mailto:gregorio.labbozzetta@insight-awp.com">Gregorio.Labbozzetta@insight-awp.com</a>
                        
                    </td>
                </tr>
                <tr>
                    <td>
                        Navjot Singh - IT - networking, machines, technical
                        troubleshooting, IT policies and procedures.
                    </td>
                    <td class="text-right">
                        <a href="tel:+1 (403) 714 4404">+1 (403) 714 4404</a> <br>
                        <a href="mailto:Navjot.Randhawa@insight-awp.com">Navjot.Randhawa@insight-awp.com</a>
                        
                    </td>
                </tr>
            </table>
        </div>
        <div class="py-3" id="computer-setup">
            <a href="#computer-setup" class="link d-block">Computer and Email Set up:</a>
            <ul>
                <li>
                    <p class="m-2">
                        You will need a laptop with the following specs.
                    </p>
                    <p class="m-2">Recommended to purchase a Windows PC</p>
                    <ul>
                        <li>512 SSD, 32GB RAM, i7 Processor (12th Gen).</li>
                        <li>Screen size is your personal preference but consider travelling and flying with a large laptop.</li>
                    </ul>
                </li>
            </ul>
            <p>
                Your purchase will require a HW requisition order that can be obtained <a href="https://insightawpcom.sharepoint.com//:x:/s/Administration/EUHa4GDI3JNDsz0ao2bodXwBk9Lm6P2-N-D_bPLODmm3Gg?e=a8sZNV&download=1">SWHWRequisition.</a> 
                <br>Once you complete the requisition you will send it to Geoff Ryan @ <a href="mailto:Geoff.ryan@insight-awp.com">Geoff.ryan@insight-awp.com</a>.
                Prior to your start date you will receive an email from our IT Manager to get your email credentials etc. setup. <br> The email will sent to your personal email and will begin with  
                <ul>
                    <li>Welcome to {{ companyName }} <em><b>New Employee Name!</b></em></li>
                   <li>I have set up a Microsoft Office 365 account for you:</li> 
                <li>http://office.com/signin</li>
                <li>{{ companyName }} Email address: <em><b>New {{ companyName }} email address</b></em></li>
                </ul>
                
            </p>
        </div>

        <div class="py-3" id="signaturePicker">
            <a href="#signaturePicker" class="link d-block">Email Signature Picker</a>
           <p>
            Once you receive the above noted email you are able to set up your email Signature Picker. We require you to set this up for all outgoing and replied to emails originating from your Insight-AWP email address.  
           </p>
           Please follow the below instructions to replace your existing signature picker for emails originating from an {{ companyName }} email address.
         <ol>
            <li>Select Insight Logo from the below logo including the tag line (on this email below)</li>
            <li>Right Click and select Copy. </li>
            <li>Open your Signatures, File -> Options ->Mail -> Signature.</li>
            <li>Select your Insight Signature</li>
            <li>Delete what you have existing. </li>
            <li>Place cursor below the last line of text and paste - Ctrl V or Right Click and Paste and select “keep sourcing format.” -  be sure to add to “Replies as stated above and then save the signature.</li>
         </ol>
         Please use the same font as you see in my email picker below (Arial 10) l for consistency and include your <b>Name, title, Company name, phone#, website address</b> – we all work for the same company so let’s make it look that way! 
         <div class="arial">John Smith</div>
         <div class="arial">AWP Facilitator</div>
         <div class="arial">{{ companyName }}</div>
         <div class="arial">123-456-7890</div>
         <div class="arial"><a style="text-decoration: underline;" href="https://insight-awp.com/">www.insight-awp.com</a></div>
              <div style="width: 140px; height: 58px;">
                <img src="https://myerpfuncstorage.blob.core.windows.net/testing/email_footer_small.png" style="width: 100%; height: 100%;">
                <p class="agency-fb-bold">The Future is Smart</p>
                </div>
         
     <br>Should you require any assistance please contact Navjot Randhawa @ <a href="mailto:Navjot.Randhawa@insight-awp.com">navjot.randhawa@insight-awp.com</a> 
        </div>


        <div class="py-3" id="software-requisition">
            <a href="#software-requisition" class="link d-block">Software Requisition:</a>
            <ul>
                <li>
                    <p class="m-2">
                        Contact Navjot for the following:
                    
                    </p>
                    <ul>
                        <li>Windows Office installation & Adobe.</li>
                        <li>Insight email address and Share Point access.</li>
                        <li>Insight TEAMS account access.</li>
                    </ul>
                </li>
                <a href="mailto:Navjot.Randhawa@insight-awp.com">Navjot.Randhawa@insight-awp.com</a>
                        / <a href="tel:+1 (403) 714 4404">+1 (403) 714 4404</a>
            </ul>
        </div>
        <div class="py-3" id="phone-policy">
            <a href="#phone-policy" class="link d-block">Phone Policy:</a>
            <ul>
                <li>
                    <p class="m-2">
                        Phone is at expense of Consultant, however, should costs for
                        business exceed beyond reasonable then reimbursement will be
                        considered.
                    </p>
                </li>
            </ul>
        </div>
        <div class="py-3" id="travel">
            <a href="#travel" class="link d-block">Travel:</a>
            <ul>
                <li>
                    <p class="m-2">
                        Should you be required to travel, please book your own flight, car
                        rental & hotel.
                    </p>
                </li>
                <li>
                    <p class="m-2">
                        Each project may have their own unique travel policies so please
                        check with Geoff Ryan prior to expensing anything related to the
                        project/s you have been assigned to.
                    </p>
                </li>
                <li>
                    <p class="m-2">
                        We use our ERP system to record and submit expenses. Please contact Janette Hess to access the application and get set up.  
                    </p>
                </li>
            </ul>
        </div>

        <div class="py-3" id="sharepoint-invoicing">
            <a href="#sharepoint-invoicing" class="link d-block">SharePoint & Invoicing:</a>
            <ul>
                <li>
                    <p class="m-2">
                        For Contractors: {{ companyName }} has a SharePoint site, so please make
                        sure to sign-up via our IT Manager and log in to find all of our
                        resources. This is also where you will find your own folder.
                        Each month you will go into your folder and upload your invoice by
                        the 25th, as to leave enough time for processing.
                    </p>
                </li>
                <li>
                    <p class="m-2">
                        Your invoice will be paid on the first of the following month.
                    </p>
                </li>
            </ul>
        </div>
        <div class="py-3" id="vehicles">
            <a href="#vehicles" class="link d-block">Vehicles:</a>
            <ul>
                <li>
                    <p class="m-2">
                        In most cases, a vehicle will be provided for transportation to and
                        from your assigned project. Each vehicle comes with a fuel and
                        maintenance card that is assigned to the vehicle and must stay with
                        it.
                    </p>
                </li>

                <li>
                    <h5 class="m-2">Fuel card prompts:</h5>
                    <ul>
                        <li>
                            Odometer: (Kindly input the number displayed on the odometer.)
                        </li>
                        <li>
                            Driver ID: <strong>7225</strong> (Driver ID is the number you input at the gas pump when you are prompted for an ID number)
                        </li>
                    </ul>
                </li>

                <li>
                    <h5 class="m-2">Maintenance on Vehicles:</h5>
                    <ul>
                        <li>
                            While the vehicle is in your care, you are responsible for taking
                            care of it.
                            <strong>Please treat it as if it is your own, it helps you make your
                                wage.</strong>
                        </li>
                        <li>
                            Oil changes and car washes can be charged to the fuel and
                            maintenance cards. If you have any questions or concerns call
                            Janette Hess and she can assist you.
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

        <div class="py-3" id="useful-websites">
            <a href="#useful-websites" class="link d-block">Useful Websites:</a>
            <ul>
                <li class="py-2">
                    INSIGHT AWP - 
                    <a href="https://insight-awp.com/" target="_blank">https://insight-awp.com/</a>
                </li>
                <li class="py-2">
                    CII (Contact Janette Hess for login info) - 
                    <a href="https://www.construction-institute.org"
                        target="_blank">https://www.construction-institute.org</a>
                </li>
                <li class="py-2">
                    COAA - 
                    <a href="https://www.coaa.ab.ca/" target="_blank">https://www.coaa.ab.ca/</a>
                </li>
                <li class="py-2">
                    AWP - 
                    <a href="https://www.workpackaging.org/" target="_blank">https://www.workpackaging.org/</a>
                </li>
            </ul>
        </div>

        <div class="py-3" id="professionalBio">
            <a href="#professionalBio" class="link d-block">Professional Bio</a>
           <p>Within our website we highlight each of our people under the “Why Insight/Our People” tab. Please download the form <a href="https://insightawpcom.sharepoint.com/:w:/s/Administration/ERB22iskW21Djp8vNuzOYecB02jq7WZC3mVdCYdt7_3vsQ?e=Oxzsa9&download=1">(Website Bio and Headshot Template.docx)</a> and send it to Geoff Ryan @ <a href="mailto:Geoff.ryan@insight-awp.com">Geoff.Ryan@insight-awp.com</a>. We prefer that you mention your personal
             life and interests that you have, not necessarily your professional experiences. Have this completed within 60 days of accepting beginning your position. </p>
        </div>

    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            currentUser: 'getLoggedInUser'
        }),
        companyName() {
             return this.currentUser && this.currentUser.userType === 'sightstream' ? 'Sightstream' : 'Insight-AWP';
        }

    }
}



</script>
<style scoped>
.link:hover {
    text-decoration: none;
}

.link {
    font-size: 22px;
    margin: 10px 0;
}

/* @font-face {
  font-family: "Agency FB";
  src: url("/path/to/agency-fb-bold.ttf") format("truetype");
  font-weight: bold;
} */

.arial {
  font-family: "Arial", sans-serif;
  font-size: 13px;
}

.agency-fb-bold {
  font-family: "Agency FB", sans-serif;
  font-size: 14.5px;
  letter-spacing: 1.75px;
  font-weight: bold;
}
</style>