<template>
  <div>
    <div
      id="mainDiv"
      style="
        border: 2px solid black;
        overflow-y: scroll;
        margin: auto;
        padding: 20px;
      "
    >
      <p
        style="
          color: black;
          text-align: center;
          font-size: 40px;
          font-weight: bolder;
        "
      >
        Code of Conduct Policy
      </p>

      <p>
        {{ companyName }} conducts business in accordance with high ethical, moral,
        and legal standards, and in the best interests of the Company, its
        employees, and contractors (hereinafter called “employees”) and other
        stakeholders. {{ companyName }} maintains business practices that will earn
        the respect of everyone with whom the Company conducts business.
      </p>

      <p>
        The following standards are intended to protect the Company's
        reputation, protect employee rights, and the quality of its services.
      </p>

      <h1 style="color: black; font-weight: bolder; margin-bottom: 8px">
        Dealing with each other
      </h1>

      <p>
        {{ companyName }} recognizes that workplace diversity is an essential
        ingredient to our success. {{ companyName }} understands that effective
        relationships are based on the recognition of the value and worth of
        each individual and that it is necessary to provide a working climate
        that is conducive to the success and well-being of all employees.
      </p>

      <p>
        {{ companyName }} works to create an atmosphere of mutual trust and respect by
        being honest, fair, and consistent. {{ companyName }} treats all employees
        fairly and impartially and strives to consistently follow Company
        policies and practices. {{ companyName }} employees listen to one another and
        foster open and honest communications. {{ companyName }} values the opinion of
        employees, respects their diverse backgrounds, encourages communication
        among employees, and solicits ideas and suggestions to improve or
        benefit the Company.
      </p>

      <p>
        {{ companyName }} prohibits the use by its employees of any social or other
        media to post or display comments about coworkers, supervisors, clients,
        or the Company that (non-exhaustively):
      </p>

      <ul>
        <li>Are vulgar, obscene, threatening, intimidating, harassing</li>
        <li>Portray the Company or individuals in a negative light</li>
        <li>
          Are a violation of other workplace policies against discrimination,
          harassment, and violence
        </li>
      </ul>

      <p>
        Posting or displaying such comments could result in disciplinary action
        being taken against an employee.
      </p>

      <h1 style="color: black; font-weight: bolder; margin-bottom: 8px">
        As an employee of the Company the expected behavior include:
      </h1>
      <ul>
        <li>Be respectful</li>
        <ul>
          <li>
            The organization is comprised of several different ethnic
            backgrounds and other differences. Be mindful and respectful of this
            when dealing with co-workers and customers.
          </li>
        </ul>
        <li style="margin-top: 5px">Be direct but professional</li>
        <ul>
          <li>
            When communicating avoid heated conversations, get your point across
            using tact and in a controlled tone.
          </li>
        </ul>
        <li style="margin-top: 5px">Be inclusive</li>
        <ul>
          <li>
            Collaboration is at the heart of our company values, and we
            encourage it within the company and with our customers.
          </li>
        </ul>
        <li style="margin-top: 5px">Understand different Perspectives</li>
        <ul>
          <li>
            Everyone in the Company has different experiences and we value and
            seek those out to be the best Company we can be.
          </li>
        </ul>
        <li style="margin-top: 5px">Lead by Example</li>
        <ul>
          <li>
            Do what you say. guide others through your behavior instead of your
            words.
          </li>
        </ul>
      </ul>

      <h1 style="color: black; font-weight: bolder; margin-bottom: 8px">
        Compliance responsibilities
      </h1>
      <p>
        {{ companyName }}'s Code of Business Conduct is to be strictly followed at all
        times and under all circumstances. Any violation will subject an
        employee to disciplinary action up to and including termination.
      </p>
      <p>
        This Code of Business Conduct applies to all employees and is not
        intended to be exhaustive. The Code of Business Conduct is intended to
        serve as a source of guiding principles to ensure all operations and
        employees approach their work in a consistent and appropriate manner,
        regardless of location, entity, or job function.
      </p>
      <p>
        It is vital that employees understand and comply with the Code of
        Business Conduct as well as recognize their continuing obligations to
        familiarize themselves with all Company policies and applicable laws
        that govern the work that we do.
      </p>
      <p>
        If questions arise about how the Code of Business Conduct applies or how
        a situation involving an ethical or legal issue should be handled,
        employees must, without delay, contact our CEO immediately.
      </p>
      <p>
        Supervisors are responsible for all employees reporting to them and must
        emphasize the importance of ethical conduct and compliance as part of
        their routine business practice. It is the obligation of all supervisors
        to see to it that there are no violations of law or policy within their
        areas of responsibility.
      </p>

      <h1 style="color: black; font-weight: bolder; margin-bottom: 8px">
        Drugs and Alcohol
      </h1>
      <p>
        You are not permitted to be under the influence of any legal or illegal
        drug that impairs your ability to perform your job, and employees are
        prohibited from manufacturing, soliciting, distributing, possessing, or
        using any illegal drugs or substances in the workplace, or while
        working. Use good judgment and keep in mind that you are expected to
        perform to your full ability at work.
      </p>
      <h1 style="color: black; font-weight: bolder; margin-bottom: 8px">
        Reputation
      </h1>
      <p>
        The reputation of {{ companyName }} is determined by how every employee
        presents herself or himself and conducts business. Honesty,
        professionalism, ethical behavior, and integrity when interacting with
        others are the cornerstones of our reputation and key to our business
        success. The Company succeeds in the marketplace through superior
        performance, not by unethical or manipulative practices.
      </p>
      <h1 style="color: black; font-weight: bolder; margin-bottom: 8px">
        Retaliation
      </h1>
      <p>
        It is unlawful to retaliate against an employee who has, in good faith,
        provided information or otherwise assisted in an investigation regarding
        a complaint. The Management Team and the Company will not tolerate any
        retaliation against an employee for complaining in good faith of
        questionable accounting, internal accounting controls, auditing matters,
        or nonfinancial matters, including violations of applicable laws,
        regulations, or internal policies, or for cooperating in the
        investigation of a complaint.
      </p>
      <h1 style="color: black; font-weight: bolder; margin-bottom: 8px">
        Reporting concerns
      </h1>
      <p>
        Employees who become aware of any concerns about accounting, internal
        controls, auditing, or other financial or nonfinancial matters,
        including violations of applicable laws, regulations, or internal
        policies, must report such issues to the Company's CEO. In the event
        that such an issue is brought to the attention of a {{ companyName }}
        employee, that person must refer the issue to the CEO.
      </p>
      <p>
        {{ companyName }}’s policy of reporting concerns are treated as strictly
        confidential. Requests for anonymity will be honored to the extent
        possible while still ensuring a full and proper investigation. It is
        important that anonymous reports contain sufficient detail for an
        investigation.
      </p>
      <h1 style="color: black; font-weight: bolder; margin-bottom: 8px">
        Avoiding Conflicts of Interest
      </h1>
      <p>
        A conflict of interest is any activity that may damage {{ companyName }}’s
        reputation or financial interests or gives the appearance of impropriety
        or divided loyalty. Avoid any situation that creates a real or perceived
        conflict of interest. If you are unsure about a potential conflict, talk
        to your Supervisor or {{ companyName }} CEO.
      </p>
      <p>
        The following are common situations employees may encounter that could
        present a conflict of interest
      </p>
      <ul>
        <li>Significant Personal Relationships</li>
        <li>Outside Activities</li>
        <li>Outside Employment and Inventions</li>
        <li>Personal Investments</li>
        <li>Gifts over $100.00 USD / $50.00 CAD.</li>
      </ul>
      <p style="color: black; font-weight: bolder; font-size: 28px">
        Diversity Policy
      </p>

      <h1 style="color: black; font-weight: 550; margin-bottom: 8px">Policy</h1>
      <p>
        {{ companyName }} recognizes and embraces the benefits of diversity among the
        Company’s workforce. The Company believes that diversity is important to
        ensure the ongoing sustainability of the business. We value a wide range
        of perspectives, experiences and expertise required to achieve desired
        outcomes. The Company is committed to a merit-based system for
        consideration for new roles and responsibilities, which requires a
        diverse and inclusive culture.
      </p>

      <p style="color: black; font-weight: bolder; font-size: 28px">
        Harassment, Bullying and Discrimination Policy
      </p>

      <h1 style="color: black; font-weight: 550; margin-bottom: 8px">Policy</h1>
      <p>
        {{ companyName }} prohibits harassment and discrimination of an employee on
        the basis of characteristics such as race, sex, gender, color,
        creed, religious beliefs, citizenship status, national origin, age,
        marital status, sexual orientation, gender identity,
        or disability. {{ companyName }} further prohibits bullying as described in
        this Policy.
      </p>
      <p>
        {{ companyName }} is committed to creating and maintaining a working
        environment that is free of such objectionable and disrespectful
        conduct. Harassment, bullying and discrimination of or by employees,
        clients, or visitors of {{ companyName }} shall not be tolerated. Any person
        who engages in harassment while acting as an employee of {{ companyName }} or
        while at an {{ companyName }} workplace is in violation of this policy. The
        term "workplace" includes both {{ companyName }} premises and off-site
        locations.
      </p>

      <h1 style="color: black; font-weight: 550; margin-bottom: 8px">
        Psychological harassment
      </h1>
      <p>
        Psychological harassment is vexatious and/or contemptuous behavior
        expressed through hostile or undesirable conduct, remarks, repeated acts
        or gestures which undermine the employee’s dignity or psychological
        integrity and cause a harmful work environment for employees.
      </p>
      <p>
        A single instance of such behavior can constitute psychological
        harassment if it is sufficiently serious and disturbs or provokes
        continued harmful effects for an employee. Psychological harassment can
        come from an individual or a group and may be directed towards one or
        several people. A person may be harassed even if he or she was not the
        intended ‘target’. For example, a person may be impacted by racist
        jokes, even when they are about a different ethnic group.
      </p>
      <p>Examples of psychological harassment may include:</p>

      <ul>
        <li>
          Unacceptable or offensive behavior resulting in the humiliation,
          belittling, exclusion or embarrassment of an employee
        </li>
        <li>
          Demeaning, degrading, offensive or humiliating comments or behavior on
          the basis of characteristics such as race, sex, gender, color, creed,
          religious beliefs, citizenship status, national origin, age, marital
          status, sexual orientation, gender identity, or disability
        </li>
        <li>
          Excessive, inappropriate or unjustified use of power or of authority,
          or the desire to harm, expressed through intimidation, threats,
          blackmail or coercion.
        </li>
      </ul>

      <h1 style="color: black; font-weight: 550; margin-bottom: 8px">
        Sexual harassment
      </h1>
      <p>
        Sexual harassment consists of unwelcome sexual advances, requests for
        sexual favors, and other verbal or physical conduct of a sexual nature
        in which the following occurs:
      </p>
      <ul>
        <li>
          Submission to such conduct is either explicitly or implicitly made a
          term or condition of employment
        </li>
        <li>
          Submission to or rejection of such conduct by an individual is used as
          a basis for employment decisions affecting such individual
        </li>
        <li>
          Such conduct has the purpose or effect of substantially interfering
          with an individual's work performance or creating an intimidating,
          hostile, or offensive employment environment
        </li>
      </ul>

      <h1 style="color: black; font-weight: 550; margin-bottom: 8px">
        Examples of prohibited conduct
      </h1>
      <p>Sexual harassment may include but is not limited to the following:</p>
      <ul>
        <li>
          Suggestive or obscene letters, notes, invitations, derogatory
          comments, slurs, jokes, epithets, assault, touching, impeding or
          blocking of movement, leering, gestures, or display of sexually
          suggestive objects, such as pictures or cartoons
        </li>
        <li>
          Continuing to express sexual interest after being informed that the
          interest is unwelcome
        </li>
        <li>
          Implying or withholding support for an appointment, promotion, or
          change of assignment or suggesting that a poor performance report will
          be prepared if sexual advances are resisted
        </li>
      </ul>

      <p>
        Coercive sexual behavior used to control, influence, or affect the
        career, salary, and/or work environment of another employee includes the
        following:
      </p>
      <ul>
        <li>
          Offering or granting favors or employment benefits, such as
          promotions, favorable performance evaluations, favorable assignments,
          favorable duties or shifts, recommendations, or reclassification, in
          exchange for sexual favors
        </li>
        <li>
          Retaliating against employees who complain (or attempt to complain)
          about the behaviors described above 
        </li>
      </ul>

      <h1 style="color: black; font-weight: 550; margin-bottom: 8px">
        Bullying
      </h1>
      <p>
        Bullying is offensive, intimidating, malicious, or insulting behaviour
        involving the misuse of power that can make a person feel vulnerable,
        upset, humiliated, undermined, or threatened. ‘Power’ does not always
        mean being in a position of authority but can include personal strength
        and coercion through fear or intimidation.
      </p>
      <p>
        Legitimate evaluative criticism and counseling of an employee’s
        performance or behavior, or reasonable instructions given to an employee
        in the course of his or her employment, will not amount to harassment or
        bullying on their own.
      </p>
      <p>Examples of bullying conduct:</p>
      <ul>
        <li>
          <span style="font-weight: bold">Verbal-</span>Slandering, ridiculing,
          or maligning a person or their family; persistent name-calling that is
          hurtful, insulting or humiliating; using a person as the butt of
          jokes; abusive and offensive remarks or nicknames. Screaming,
          shouting, yelling, or swearing at someone in public or private;
          trivializing another’s work or failing to give someone credit for
          their work.
        </li>
        <li>
          <span style="font-weight: bold">Physical bullying</span>Pushing,
          shoving, kicking, poking, tripping, assault or threat of physical
          assault, damage to a person's work area or property.
        </li>
        <li>
          <span style="font-weight: bold">Nonverbal</span>Obscene, threatening,
          or intimidating gestures, including staring or glaring.
        </li>
        <li>
          <span style="font-weight: bold">Social</span>Socially or physically
          excluding or disregarding a person in work-related activities; giving
          the “silent treatment”; persistently singling out one person.
        </li>
      </ul>
      <p>
        {{ companyName }} is committed to creating and maintaining a working
        environment that is free of objectionable and disrespectful conduct.
        Harassment, bullying, or discrimination of or by employees, clients, or
        visitors of {{ companyName }} will not be tolerated. Any person who engages in
        harassment, bullying, or discrimination while acting as an employee of
        {{ companyName }} or while at a {{ companyName }} workplace is in violation of this
        policy and may be guilty of gross misconduct. The term ‘workplace’
        includes both {{ companyName }} premises and off-site locations, including
        social media.
      </p>

      <h1 style="color: black; font-weight: 550; margin-bottom: 8px">
        Harassment, bullying and discrimination complaint procedures
      </h1>
      <p>
        Any employee who believes that they are being harassed, discriminated
        against or bullied by a supervisor, colleague, or other individual with
        whom they come in contact as a result of their employment with
        {{ companyName }} must immediately inform their direct supervisor or the
        Company’s CEO providing, where possible, the following information:
      </p>
      <ul>
        <li>Description of the offensive behavior</li>
        <li>Identity of the alleged offender</li>
        <li>Date(s) the incident(s) took place</li>
        <li>
          Details of any discussions with the alleged offender about the
          offensive behavior
        </li>
        <li>Surrounding circumstances</li>
        <li>
          Witnesses to the offensive behavior or other people who may have
          first-hand knowledge of the incident(s) or surrounding circumstances
        </li>
      </ul>
      <p>
        Supervisors, upon receiving a complaint, are to immediately forward this
        information to the Company’s CEO.
      </p>
      <p>
        Investigation of a complaint normally includes interviews of the parties
        involved and any named or apparent witnesses. In determining whether the
        alleged conduct constitutes harassment, investigators are to consider
        the record as a whole, as well as the total circumstances. Complaints
        are to be investigated in a timely manner.
      </p>
      <p>
        The initiation of a complaint in good faith will not reflect in any way
        on the recruitment, hiring, transfer, promotion, or other terms and
        conditions of employment of the complainant.
      </p>
      <p>
        Confidentiality will be safeguarded insofar as possible while
        recognizing other legal and business obligations of {{ companyName }}.
      </p>
      <p>
        An employee who sees, believes, or knows that a colleague, client, or
        other individual with whom they have contact through their employment
        with {{ companyName }} is the victim or perpetrator of conduct that falls
        within this policy is to report it to their supervisor, or CEO or other
        Senior Management individual.
      </p>
      <p>
        An employee's response to incidents of harassment, bullying or
        discrimination is not limited to the above procedures. In some
        circumstances, it may be appropriate to contact outside authorities,
        such as the police. An employee has the right to file a complaint with
        the Equal Employment Opportunity Commission (EEOC) (in the United
        States) and with appropriate state or provincial agencies.
      </p>

      <p style="color: black; font-weight: bolder; font-size: 28px">
        Equal Employment Opportunity (EEO) Policy
      </p>
      <p>
        {{ companyName }} is committed to workplace diversity and inclusion and
        closely follows the equitable employment standards in its jurisdictions.
      </p>

      <h1 style="color: black; font-weight: 550; margin-bottom: 8px">
        Equal Employment Opportunity (US)
      </h1>
      <p>
        {{ companyName }} is an Equal Employment Opportunity employer. Our policy is
        to provide equal opportunity to all employees and applicants and to
        prohibit any discrimination because of race, color, religion, sex,
        national origin, age, marital status, genetic information, disability,
        pregnancy, protected veteran status, sexual orientation or gender
        identity and expression. Employees will be treated based on their
        job-related qualifications, ability, and performance. Discrimination and
        harassment, including sexual harassment, is against the law, against
        {{ companyName }} policy, and will not be tolerated. {{ companyName }} will provide
        reasonable accommodation for employees and applicants with
        disabilities. The foundation of these policies is our commitment to
        treat everyone fairly and equitably and to have an unbiased work
        environment.
      </p>

      <p>
        If you have any questions or would like to report a claim of
        discrimination or harassment, contact Geoff Ryan at
        <a href="mailto:geoff.ryan@insight-awp.com"
          >Geoff.ryan@insight-awp.com</a
        >
      </p>

      <h1 style="color: black; font-weight: 550; margin-bottom: 8px">
        Employment Equity (Canada)
      </h1>
      <p>
        Employment equity practices seek to ensure that no one is denied
        employment opportunities for reasons related to one's ability.
        Employment equity also seeks to correct disadvantages in employment
        experienced by four designated groups: women, people with disabilities,
        Indigenous peoples, and visible minorities.
      </p>
      <p>
        {{ companyName }} abides by the Employment Equity Act in Canada, which
        requires federal jurisdiction employers to engage in proactive
        employment practices to increase the representation of the
        aforementioned designated groups. Our policy is to provide equitable
        employment opportunity to all employees and applicants. {{ companyName }}
        policy and the law prohibit discrimination based on race or ethnic
        origin, colour, religion, age, sex, sexual orientation, gender identity
        or expression, marital status, family status, disability or genetic
        characteristics.
      </p>
      <p>
        If you have any questions or would like to report a claim of
        discrimination or harassment, contact Geoff Ryan
        <a href="mailto:geoff.ryan@insight-awp.com"
          >Geoff.ryan@insight-awp.com</a
        >.
      </p>

      <h1 style="color: black; font-weight: 550; margin-bottom: 8px">
        Equal Employment Opportunity (Global Regions)
      </h1>
      <p>
        Employees will be treated based on their job-related qualifications,
        ability, and performance. Our policy is to provide equal opportunity to
        all employees, applicants, and other people in the business and to
        prohibit any discrimination as described in the laws of the relevant
        country; these will commonly include age, disability, gender
        reassignment, marital or civil partner status, pregnancy or maternity,
        race, colour, nationality, ethnic or national origin, religion or
        belief, sex, or sexual orientation. As well, our policy is that sexual
        harassment or any other kind of harassment will not be tolerated. The
        foundation of these policies is our commitment to treat everyone fairly
        and equally and to have an unbiased work environment.
      </p>
      <p>
        If you believe you have been unfairly discriminated against, you are
        urged to bring the matter to the attention of your supervisor or to the
        Company CEO, Geoff Ryan,
        <a href="mailto:geoff.ryan@insight-awp.com"
          >Geoff.ryan@insight-awp.com</a
        >
      </p>
    </div>
    <div id="acknowledgeMsgDiv" style="margin: auto; padding: 20px">
      <input
        type="checkbox"
        value="1"
        id="acknowledge"
        ref="acknowledge"
        style="height: 20px; width: 20px"
        @click="acknowledge"
      />
      <label id="acknowledgeLabel" style="font-weight: bold" for="acknowledge">
        I acknowledge
      </label>
      <div v-if="isAcknowledged" id="acknowledgeMsg" style="float: right">
        {{ message }}
      </div>
    </div>

    <b-modal
      hide-footer
      centered
      title="Acknowledgment "
      id="acknowledgeModal"
      body-class="p-0"
      no-close-on-backdrop
      @hide="closeModal"
    >
      <div class="d-block text-center p-3">
        I acknowledge reading and adhering to {{ companyName }}'s Code of Conduct.
      </div>
      <hr class="m-0" />
      <div class="p-3">
        <b-button variant="outline-danger" @click="closeModal">No</b-button>
        <b-button class="ml-3" variant="outline-primary" @click="acknowledged"
          >Yes</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isAcknowledged: false,
      message: ""
    };
  },
  computed: {
      ...mapGetters({
          currentUser: 'getLoggedInUser'
      }),
      companyName() {
            return this.currentUser && this.currentUser.userType === 'sightstream' ? 'Sightstream' : 'Insight-AWP';
      }

  },
  mounted() {
    this.getAcknowledgement();
  },
  methods: {
    acknowledge() {
      if (this.$refs.acknowledge.checked) {
        this.$bvModal.show("acknowledgeModal");
      }
    },
    closeModal() {
      this.$refs.acknowledge.checked = false;
      this.$bvModal.hide("acknowledgeModal");
    },
    async acknowledged() {
      this.setLoading(true);
      let user = localStorage.getItem("user");
      user = JSON.parse(user);
      await this.$axios
        .post("/codeOfConduct", { user: user.username })
        .then((response) => {
          if (response.data.message === "Failed") {
            this.makeToast(
              "danger",
              "There was a problem while acknowledging."
            );
          } else {
            this.makeToast("success", "Acknowledged.");
            this.setLoading(false);
            this.getAcknowledgement();
            this.$bvModal.hide("acknowledgeModal");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getAcknowledgement() {
      this.setLoading(true);
      await this.$axios
        .get("/codeOfConduct")
        .then((response) => {
          if (response.data.message === "Failed") {
            this.makeToast("danger", "No acknowledgement yet.");
            this.setLoading(false);
          } else {
            const date = new Date(
              response.data.dataList[0].AcknowledgeTimestamp
            ); // assuming the date is returned in the response
            const options = {
              weekday: "long",
              month: "long",
              day: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              timeZoneName: "short"
            };
            const formattedDate = date.toLocaleString("en-US", options);
            console.log(formattedDate); // log the formatted date string
            this.isAcknowledged = true;
            this.$refs.acknowledge.checked = true;
            let user = localStorage.getItem("user");
            user = JSON.parse(user);
            this.message = `${user.name}` + " acknowledged on " + formattedDate;
            this.$refs.acknowledge.disabled = true;
            this.setLoading(false);
            this.$bvModal.hide("acknowledgeModal");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
};
</script>
<style>
#acknowledgeMsgDiv {
  width: 70%;
}
#acknowledgeLabel {
  font-size: 22px;
}
#acknowledgeMsg {
  width: 40%;
}
#mainDiv {
  height: 82vh;
  width: 70%;
}
@media only screen and (max-width: 1700px) {
  #acknowledgeLabel {
    font-size: 17px;
  }
  #acknowledgeMsg {
    font-size: 12px;
    width: 47%;
  }

  #mainDiv {
    height: 78vh;
    width: 75%;
  }
  #acknowledgeMsgDiv {
    width: 75%;
  }
}
</style>
