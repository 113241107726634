<template>
  <div class="container">
    <h1 class="text-center mb-4">
      Welcome to {{ companyName }}
      <em><b>{{ message }}</b></em>!
    </h1>
    <p>
      This onboarding email will guide you through the first steps and help you
      settle in smoothly:
    </p>
    <div class="py-3" id="microsoft-account">
      <a href="#microsoft-account" class="link d-block">Microsoft Account:</a>
      <ul>
        <li>
          We have set up a Microsoft Office 365 account for you at this
          <a href="https://office.com/signin" target="_blank">link.</a>
        </li>
      <li>
        <b>{{ companyName }} Email address:</b> <em>{{ email }}</em>
      </li>
        <!-- <li>
          The first time you log into your email account you will be asked to
          change your password, here is your temp password for the first time
          you log in:<br />
          <b><em>Guru8813</em></b>
        </li> -->
      </ul>
      <br />
      <ul>
        <li>
          Also, you will be asked to setup your MFA (multifactor authentication)
          Mobile and other email address.
        </li>
        <li>
          You can download Office 365 onto 5 Devices from the
          <a href="https://office.com/signin" target="_blank"
            >Office 365 online portal</a
          >, click on download on the main page.
        </li>
        <li>
          You will need to
          <a
            href="https://www.microsoft.com/en-us/microsoft-teams/download-app"
            target="_blank"
            >download</a
          >
          Microsoft Teams if you don’t have it installed.
        </li>
        <li>OneDrive is bundled with Office 365.</li>
        <li>
          You can access the Company SharePoint at this
          <a
            v-if="currentUser && currentUser.userType === 'sightstream'"
            href="https://insightawpcom.sharepoint.com/sites/Sightstream_Environment"
            target="_blank"
          >
            link
          </a>
          <a v-else href="https://insightawpcom.sharepoint.com/" target="_blank">
            link
          </a>
        </li>
      </ul>
    </div>
    <div class="py-3" id="personal-folder">
      <a href="#personal-folder" class="link d-block">Personal Folder:</a>
      <ul>
        <li>
          You can access your personal folder at this
          <a
            href="https://insightawpcom.sharepoint.com/sites/Insight-AWP2/SitePages/Personal-Folders.aspx"
            target="_blank"
            >link</a
          >. Please click on your name box
        </li>
        <li>
          You can use this folder to store your personal files and invoices
        </li>
      </ul>
    </div>

    <div class="py-3" id="erp">
      <a href="#erp" class="link d-block">ERP:</a>
      <ul>
        <li>
          You can access our ERP at this
          <a href="https://erp.insight-awp.com/" target="_blank">link</a>.
        </li>
        <li>
          To log into the {{ companyName }} ERP system you will need to use your
          {{ companyName }} email and password.
        </li>
      </ul>
    </div>

    <div class="py-3" id="company-onboarding">
      <a href="#company-onboarding" class="link d-block">Company Onboarding:</a>
      <ul>
        <li>
          Please review the {{ companyName }} onboarding information in the ERP at
          this
          <a href="https://erp.insight-awp.com/onboarding" target="_blank"
            >link</a
          >.
        </li>
        <li>
          Please acknowledge the {{ companyName }} Code of Conduct at this
          <a href="https://erp.insight-awp.com/codeOfConduct" target="_blank"
            >link</a
          >.
        </li>
        <li>
          Please compile the form at this
          <a href="https://erp.insight-awp.com/profile" target="_blank">link</a>
          with your personal information and click “Update.”
        </li>
      </ul>
    </div>

    <div class="py-3" id="timesheet-expenses">
      <a href="#timesheet-expenses" class="link d-block"
        >Timesheet and Expenses:</a
      >
      <ul>
        <li>You can use the ERP System to compile Timesheets, and expenses.</li>
        <li>
          Click at this
          <a
            href="https://erp.insight-awp.com/timesheets/create"
            target="_blank"
            >link</a
          >
          to view the Timesheet page.
        </li>
        <li>
          Click at this
          <a href="https://erp.insight-awp.com/expenses/create" target="_blank"
            >link</a
          >
          to view the Expenses page.
        </li>
      </ul>
    </div>
    <div class="py-3">
      <p>
        If you have any questions or problems on this setup, please reach out to
        <a href="mailto:Navjot.Randhawa@insight-awp.com">Navjot</a> or
        <a href="mailto:gregorio.labbozzetta@insight-awp.com">Gregorio</a>
        anytime
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isAcknowledged: false,
      message: "",
      email: ""
    };
  },
   computed: {
    ...mapGetters({
      currentUser: 'getLoggedInUser'
    }),
    companyName() {
      return this.currentUser && this.currentUser.userType === 'sightstream' ? 'Sightstream' : 'Insight-AWP';
    }
  },
  mounted() {
    this.getAcknowledgement();
  },
  methods: {
    async getAcknowledgement() {
      let user = localStorage.getItem("user");
      user = JSON.parse(user);
      console.log(user);
      this.message = `${user.name}`;
      this.email = `${user.username}`;
    }
  }
};
</script>
<style scoped>
.link:hover {
  text-decoration: none;
}

.link {
  font-size: 22px;
  margin: 10px 0;
}

/* @font-face {
  font-family: "Agency FB";
  src: url("/path/to/agency-fb-bold.ttf") format("truetype");
  font-weight: bold;
} */

.arial {
  font-family: "Arial", sans-serif;
  font-size: 13px;
}

.agency-fb-bold {
  font-family: "Agency FB", sans-serif;
  font-size: 14.5px;
  letter-spacing: 1.75px;
  font-weight: bold;
}
</style>
