<template>
  <div class="container">
    <h1 class="text-center mb-4">Company Software</h1>
    <p>
      This module provides links to all the essential software used at
      {{ companyName }}. Click on the software name or image to visit the respective
      website.
    </p>
    <div class="py-3" id="software-links">
      <a href="#software-links" class="link d-block">Company Software Links:</a>
      <div class="row">
        <div
          class="col-md-4 mb-4"
          v-for="software in softwareList"
          :key="software.name"
        >
          <div class="card h-100 software-card">
            <a :href="software.link" target="_blank">
              <img
                :src="software.logo"
                :alt="software.name"
                class="card-img-top software-logo"
              />
              <div class="card-body">
                <h5 class="card-title">{{ software.name }}</h5>
                <p class="card-text">{{ software.description }}</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "CompanySoftwarePortal",
  data() {
    return {
      softwareList: [
        {
          name: "Teams",
          link: "https://teams.microsoft.com",
          logo: require("@/assets/images/software/Teams.png"),
          description: "Video conferencing and online meetings."
        },
        {
          name: "Azure",
          link: "https://azure.microsoft.com",
          logo: require("@/assets/images/software/azure.png"),
          description: "Cloud computing services by Microsoft."
        },
        {
          name: "Zoom",
          link: "https://zoom.us",
          logo: require("@/assets/images/software/zoom.png"),
          description: "Video conferencing and online meetings."
        },
        {
          name: "Adobe",
          link: "https://www.adobe.com",
          logo: require("@/assets/images/software/adobe.png"),
          description: "Creative software and digital media solutions."
        },
        {
          name: "ESET",
          link: "https://www.eset.com",
          logo: require("@/assets/images/software/eset.png"),
          description: "Antivirus and internet security software."
        },
        {
          name: "GoDaddy",
          link: "https://www.godaddy.com",
          logo: require("@/assets/images/software/godaddy.png"),
          description: "Domain registration and web hosting."
        },
        {
          name: "WordPress",
          link: "https://wordpress.com",
          logo: require("@/assets/images/software/wordpress.png"),
          description: "Website creation and blogging platform."
        },
        {
          name: "Domain.com",
          link: "https://www.domain.com",
          logo: require("@/assets/images/software/domain.png"),
          description: "Domain registration and web hosting."
        },
        {
          name: "Trimble",
          link: "https://www.trimble.com",
          logo: require("@/assets/images/software/trimble.png"),
          description: "Geospatial and positioning solutions."
        },
        {
          name: "DocuSign",
          link: "https://www.docusign.com",
          logo: require("@/assets/images/software/docusign.png"),
          description: "Electronic signature and agreement cloud."
        },
        {
          name: "Autodesk",
          link: "https://www.autodesk.com",
          logo: require("@/assets/images/software/autodesk.png"),
          description: "Design and engineering software."
        },
        {
          name: "ClickUp",
          link: "https://www.clickup.com",
          logo: require("@/assets/images/software/clickup.png"),
          description: "Project management and productivity software."
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
        currentUser: 'getLoggedInUser'
    }),
    companyName() {
          return this.currentUser && this.currentUser.userType === 'sightstream' ? 'Sightstream' : 'Insight-AWP';
    }
  }
}







</script>

<style scoped>
.container {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.text-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.description {
  font-size: 1.1rem;
  color: #6c757d;
}

.link {
  font-size: 22px;
  margin: 10px 0;
  color: #007bff;
}

.link:hover {
  text-decoration: none;
  color: #0056b3;
}

.card-img-top {
  width: 100%;
  max-height: 160px; /* Set a maximum height for all images */
  object-fit: contain; /* Ensure images fit within the specified dimensions without being cropped */
  margin-top: 10px; /* Add spacing at the top of the image */
  padding-top: 10px;
}

.card-body {
  padding: 10px;
}

.card-title {
  font-size: 18px;
  margin: 0;
  color: #343a40;
}

.card-text {
  font-size: 14px;
  color: #6c757d;
}

.software-card {
  border: 1px solid #e0e0e0; /* Light grey border */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
}

.software-card:hover {
  transform: scale(1.05); /* Slightly expand the card on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

.shadow-sm {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.stretched-link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
</style>
