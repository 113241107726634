<template>
  <b-form @submit.stop.prevent>
    <b-row>
      <b-col cols="12">
        <!-- <div class="form-row" style="display:node;">
          <div class="form-group col-12 col-sm-4 col-md-3 col-lg-2">
            <label>Task Performed:</label>
          </div>
          <div class="form-group col-12 col-sm-8 col-md-9 col-lg-10" >
            <ValidationProvider
              name="Activity"
              :rules="rules.activity"
              v-slot="{ errors }"
            >
              <b-input-group>
                <template #append>
                  <b-input-group-text
                    ><span> {{50 - batch.activity.length}} </span>&nbsp;characters limit</b-input-group-text
                  >
                </template>
                <b-form-input
                  id="Activity"
                  v-model="batch.activity"
                  type="text"
                  :maxlength="50"
                  name="Activity"
                  placeholder="Add Task you performed"
                ></b-form-input>
              </b-input-group>
              <span class="text-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
        </div> -->
        <div class="form-row">
          <div class="form-group col-12 col-sm-4 col-md-3 col-lg-2">
            <label>Project:</label>
          </div>
          <div class="form-group col-12 col-sm-8 col-md-9 col-lg-10">
            <ValidationProvider
              name="Project"
              :rules="rules.project"
              v-slot="{ errors }"
            >
              <select
                ref="projectValidator"
                class="form-control"
                v-model="batch.projectId"
              >
                <option value disabled selected>Choose Project</option>
                <option
                  :key="project.ProjectID"
                  v-for="project in projects.filter(project => project.is_active === 1)"
                  :value="project.ProjectID"
                >
                  {{ project.ProjectName }}
                </option>
              </select>
              <span class="text-danger" v-if="projectValidation"
                >Project must have a value</span
              >
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div
            class="form-group col-12 col-sm-4 col-md-3 col-lg-2"
            v-if="batch.projectId === 45"
          >
            <label>Sub Project:</label>
          </div>
          <div
            class="form-group col-12 col-sm-8 col-md-9 col-lg-10"
            v-if="batch.projectId === 45"
          >
            <ValidationProvider
              name="Sub Project"
              :rules="rules.subProject"
              v-slot="{ errors }"
            >
              <select
                ref="subProjectValidator"
                class="form-control"
                v-model="batch.subProjectId"
              >
                <option value disabled selected>Choose Project</option>
                <option
                  :key="filteredProject.ProjectID"
                  v-for="filteredProject in filteredProjects"
                  :value="filteredProject.ProjectID"
                >
                  {{ filteredProject.ProjectName }}
                </option>
              </select>
              <span class="text-danger" v-if="subProjectValidation"
                >Sub Project must have a value</span
              >
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-4 col-md-3 col-lg-2">
            Select Day(s):
          </div>
          <div class="form-group col-12 col-sm-8 col-md-9 col-lg-10">
            <vc-calendar
              is-expanded
              :attributes="attributes"
              @dayclick="dayClicked"
              :available-dates="{ start: new Date(), end: null }"
              :min-date="min_date"
              :max-date="max_date"
            />
          </div>
        </div>
        <div v-if="this.batch.time.length > 1" class="form-row addall-row">
          <div class="form-group col-12 col-sm-4 col-md-3 col-lg-2"></div>
          <div class="form-group col-12 col-sm-8 col-md-9 col-lg-10">
            <div class="row">
              <div class="col-md-4">
                <ValidationProvider name="Project" v-slot="{ errors }">
                  <b-form-input
                    v-model="multipleHours"
                    step="1"
                    min="0"
                    type="number"
                  ></b-form-input>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-md-4">
                <b-button @click="applyMultipleHours" block variant="secondary"
                  >Apply Hours To All</b-button
                >
              </div>
              <div class="col-md-4">
                <b-button @click="clearMultipleHours" block variant="secondary"
                  >Clear All</b-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-sm-4 col-md-3 col-lg-2"></div>
          <div class="form-group col-12 col-sm-8 col-md-9 col-lg-10">
            <div class="hour-list">
              <div
                class="row"
                :key="time.date.getTime()"
                v-for="time in batch.time"
                style="padding: 0.5em 0.25em"
              >
                <div class="col-md-2">
                  <b-form-input
                    :value="formatDate(time.date)"
                    :readonly="true"
                    type="text"
                  ></b-form-input>
                </div>
                <div class="col-md-2">
                  <ValidationProvider
                    name="Hours"
                    :rules="rules.hour"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="time.hours"
                      @blur="() => timeChanged(time)"
                      type="number"
                      step="1"
                      placeholder="Hours"
                    ></b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="col-md-8">
                  <ValidationProvider
                    name="Comment"
                    :rules="rules.comment"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      v-model="time.comment"
                      type="text"
                      :maxlength="50"
                      placeholder="Activity Comment (Required)"
                      required
                    ></b-form-input>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-col cols="12">
      <div class="row">
        <div class="col-3"></div>
        <div class="col-9">
          <div class="row">
            <div class="col-9"></div>
            <div class="col-3 nopad-right">
              <b-button
                type="button"
                @click="saveDataAndReset"
                block
                variant="primary"
                class="admin"
                v-bind:class="{ 'disabled-button': !isDisabled }"
                >Save</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </b-col>
  </b-form>
</template>

<script>
import VCalendar from "v-calendar";
import Vue, { getCurrentInstance } from "vue";
import FormatUtilities from "../utilities/FormatUtilities";
import { mapRangeToDates } from "../utilities/DateUtilities";
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { min_number } from "../utilities/CustomRules";
import { required } from "vee-validate/dist/rules";
import { integer } from "vuelidate/lib/validators";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

extend("required", {
  ...required,
  message: "{_field_} must have a value"
});

extend("min_number", min_number);

Vue.use(VCalendar, {
  componentPrefix: "vc"
});

export default {
  props: {
    batch: Object,
    projects: Array,
    isDisabled: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      selectedDates: [],
      multipleHours: null,
      lastClickedDay: null,
      projectValidation: false,
      subProjectValidation: false,
      rules: {
        activity: { required: true },
        project: { required: true },
        subProject: { required: true },
        hour: { required: true, min_number: { min: 0 } },
        comment: { required: true }
      }
    };
  },

  computed: {
    dates() {
      return this.selectedDates.map((day) => day);
    },
    filteredProjects() {
      // Filter the projects array to exclude the project with Project ID 4
      return this.projects.filter((project) => project.ProjectID !== 45);
    },
    attributes() {
      return this.dates.map((date) => ({
        highlight: true,
        highlight: "green",
        dates: date
      }));
    },
    min_date() {
      // return "2023-06-01";
      return this.$moment()
        .utc()
        .subtract(2, "months")
        .startOf("month")
        .format("YYYY/MM/DD");
      // const currentDate = new Date();
      // const currentMonth = currentDate.getMonth();
      // const currentYear = currentDate.getFullYear();
      // const minDate = new Date(currentYear, currentMonth, 26); // Set to the 26th of the current month
      // return minDate;
    },
    max_date() {
      // return "2023-06-15";
      return this.$moment()
        .utc()
        .add(2, "months")
        .endOf("month")
        .format("YYYY/MM/DD");
      // const currentDate = new Date();
      // const currentMonth = currentDate.getMonth();
      // const nextMonth = currentMonth + 1;
      // const currentYear = currentDate.getFullYear();
      // const maxDate = new Date(currentYear, nextMonth, 25); // Set to the 25th of the next month
      // return maxDate;
    }
  },

  methods: {
    formatDate: FormatUtilities.formatDate,

    clearMultipleHours() {
      this.multipleHours = "";
      this.batch.time = [];
      this.selectedDates = [];
    },

    applyMultipleHours() {
      if (
        this.multipleHours == null ||
        typeof this.multipleHours == "undefined" ||
        parseInt(this.multipleHours) === NaN
      ) {
        return;
      }
      this.batch.time.forEach(
        (time) => (time.hours = parseInt(this.multipleHours))
      );
    },

    dayClicked(dayInfo) {
      const dateClicked = dayInfo.date;

      const ranges = this.selectedDates.filter((d) => !(d instanceof Date));

      if (dayInfo.event.shiftKey && this.lastClickedDay !== null) {
        const range = { start: this.lastClickedDay, end: dayInfo.date };
        if (range.start > range.end) {
          let _end = range.end;
          range.end = range.start;
          range.start = _end;
        }
        const intersectIndex = ranges.findIndex((r) =>
          this.doesRangeInsersect(r, range)
        );
        const dates = mapRangeToDates(range);

        if (intersectIndex < 0) {
          dates.forEach((date) => {
            const index = this.selectedDates.findIndex(
              (d) => d instanceof Date && d.getTime() === date.getTime()
            );
            if (index >= 0) {
              this.selectedDates.splice(index, 1);
            }
            this.batch.deleteTime(date);
            // console.log(date);
            this.batch.addTime(date);
            console.log(this.batch.time);
          });

          this.selectedDates.push(range);
          this.lastClickedDay = null;
        }
        return;
      }

      const index = this.selectedDates.findIndex(
        (r) => !(r instanceof Date) && this.doesDateIntersect(r, dateClicked)
      );
      if (index >= 0) {
        const range = this.selectedDates[index];
        const dates = mapRangeToDates(range);

        dates.forEach((date) => this.batch.deleteTime(date));

        this.selectedDates.splice(index, 1);
        this.lastClickedDay = null;
        return;
      }
      this.addUpdateDate(dateClicked);
      this.lastClickedDay = dayInfo.date;
    },

    /**
     * Determines if the given date is within the
     * given range
     */
    doesDateIntersect(range1, date) {
      return (
        date.getTime() >= range1.start.getTime() &&
        date.getTime() <= range1.end.getTime()
      );
    },

    /**
     * Tests to see if range1 and range2 intersect
     */
    doesRangeInsersect(range1, range2) {
      if (!range1.start) {
        return false;
      }
      const areEqual =
        range1.start.getTime() === range2.start.getTime() &&
        range1.end.getTime() === range2.end.getTime();

      const range2Before = range2.end.getTime() < range1.start.getTime();
      const range2After = range2.end.getTime() > range1.end.getTime();

      return areEqual || (!range2Before && !range2After);
    },

    /**
     * Adds or updates the given date,
     * on both the batch object and the date array.
     */
    addUpdateDate(date) {
      const selectedIndex = this.selectedDates.findIndex((d) =>
        this.compareDates(d, date)
      );
      if (selectedIndex >= 0) {
        this.selectedDates.splice(selectedIndex, 1);
        this.batch.deleteTime(date);
        return;
      }
      this.selectedDates.push(date);
      // console.log(date);
      this.batch.addTime(date);
      console.log(this.batch.time[0].date);
    },

    compareDates(date1, date2) {
      if (date1 instanceof Date && date2 instanceof Date) {
        return date1.getTime() == date2.getTime();
      } else {
        return false;
      }
    },

    saveDataAndReset() {
      if (this.$refs.projectValidator.value !== "") {
        try {
          if (this.$refs.subProjectValidator.value !== "") {
            this.$emit("saveData");
            this.clearMultipleHours();
          } else {
            this.subProjectValidation = true;
          }
        } catch (err) {
          this.$emit("saveData");
          this.clearMultipleHours();
        }
      } else {
        this.projectValidation = true;
      }
    },

    /**
     * Called any time, int the multiple entry
     * has changed.
     * @param {any} time the time that has changed
     */
    timeChanged(time) {
      time.isEdited = true;
    },

    enableRules() {
      this.rules = {
        activity: { required: true },
        project: { required: true },
        comment: { required: true }
      };
      //this.rules.hour.required = true;
      // this.rules.hour.min_number = {min: 0};
    },

    disableRules() {
      this.rules = {
        activity: { required: false },
        project: { required: false },
        hour: { required: false, min_number: false },
        comment: { required: false }
      };
    }
  },
  watch: {
    "batch.projectId": {
      handler(updatedProject) {
        this.projectValidation = false;
        // change the projFilter prop to 0
        this.$emit("reset-proj-filter");
      },
      deep: true // Enable deep watching if necessary
    },
    "batch.subProjectId": {
      handler(updatedProject) {
        this.subProjectValidation = false;
      },
      deep: true // Enable deep watching if necessary
    }
  }
};
</script>
