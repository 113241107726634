<template>
  <div id="admin-window">
    <b-container fluid="sm">
      <b-row>
        <b-col><h1 class="buttonpad">Contractor Timesheet Review</h1></b-col>
        <b-col id="CommonReportBtn" class="align-right">
          <!-- <b-button
            href="https://app.powerbi.com/groups/f3a21ed2-f377-4e4c-9c9f-30822b18936c/rdlreports/07ab9297-8d98-47f3-a8bf-e7372b119bb5"
            target="_blank"
            variant="primary"
            >Download Timesheets</b-button -->
          <b-button
            @click="downloadTimesheets"
            id="downloadTimesheetsFromPowerBi"
            variant="primary"
            >Download Timesheets</b-button
          >
        </b-col>
        <!-- <b-col id="SavannahMainReportBtn" cols="4" style="display: none">
          <b-col class="align-right">
            <b-button
              href="https://app.powerbi.com/groups/f3a21ed2-f377-4e4c-9c9f-30822b18936c/rdlreports/0a13515b-edce-4c5f-aef8-81531ce31294"
              target="_blank"
              variant="primary"
              >Savannah River Main Report</b-button
            >
          </b-col>
        </b-col>
        <b-col id="SavannahTitleReportBtn" cols="4" style="display: none">
          <b-col class="align-right">
            <b-button
              href="https://app.powerbi.com/groups/f3a21ed2-f377-4e4c-9c9f-30822b18936c/rdlreports/596a4256-de39-4090-8831-0dd5f6545868"
              target="_blank"
              variant="primary"
              >Savannah River Title Report</b-button
            >
          </b-col>
        </b-col> -->
      </b-row>
      <hr />
      <b-row>
        <b-col :class="filter.project === 45 ? 'col-2' : 'col-3'">
          <b-form-group label="Select Owner:" label-for="selectedOwner">
            <b-select
              v-model="filter.owner"
              class="control-no-left-padding form-control"
            >
              <option value disabled selected>Please choose...</option>
              <option value="1">Insight-AWP</option>
              <option value="2">Sightstream</option>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col :class="filter.project === 45 ? 'col-2' : 'col-3'">
          <b-form-group
            id="fieldset1"
            label=" Select Employee:"
            label-for="user"
          >
            <b-select
              v-model="filter.user"
              class="form-control"
              ref="fieldsett"
              :disabled="!filter.owner"
            >
              <option :value="null" selected>Please choose...</option>
              <!-- <option
                :key="user.id"
                v-for="user in users.filter((user) =>
                  filter.owner ? user.UserOwnerID == filter.owner : true
                )"
                :value="user.id"
              >
                {{ user.Name || "(Unknown User)" }}
              </option> -->
              <option
                :key="user.originalIndex"
                v-for="user in filteredUsers"
                :value="user.originalIndex"
              >
              {{ user.Name || "(Unknown User)" }}
            </option>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col :class="filter.project === 45 ? 'col-2' : 'col-3'">
          <b-form-group
            id="fieldset2"
            label=" Select Date Range:"
            label-for="selectedPeriod"
          >
            <b-select
              ref="periodOfTimesheets"
              id="periodOfTimesheets"
              v-model="filter.date_range"
              class="control-no-left-padding form-control"
            >
              <option :value="null" selected>Please choose...</option>
              <option
                :key="index"
                v-for="(period, index) in periods"
                :value="period.key"
              >
                {{ period.value }}
              </option>
            </b-select>
          </b-form-group>
        </b-col>
        <b-col :class="filter.project === 45 ? 'col-3' : 'col-3'">
          <b-form-group label=" Select Project:" label-for="selectedProject">
            <b-select
              ref="fieldsetProject"
              id="fieldsetProject"
              v-model="filter.project"
              class="control-no-left-padding form-control"
              @change="checkOwnerID"
            >
              <option :value="null" selected>Please choose...</option>
              <option
                :key="index"
                v-for="(project, index) in projects"
                :value="project.projectID"
              >
                {{ project.ProjectName }}
              </option>
            </b-select>
            <div
              v-if="validateProject"
              id="projectValidation"
              style="color: red"
            >
              Project is required.
            </div>
          </b-form-group>
        </b-col>

        <b-col :class="'col-3'" v-if="filter.project === 45">
          <b-form-group label="Select Sub Project:" label-for="selectedProject">
            <b-select
              ref="subsetProject"
              id="subsetProject"
              v-model="filter.subProject"
              class="control-no-left-padding form-control"
              @change="checkOwnerID"
            >
              <option :value="null" selected>Please choose...</option>
              <option
                :key="index"
                v-for="(project, index) in projects.filter(
                  (project) => project.projectID !== 45
                )"
                :value="project.projectID"
              >
                {{ project.ProjectName }}
              </option>
            </b-select>
          </b-form-group>
        </b-col>
      </b-row>

      <div v-if="filter.date_range">
        <div class="spacer"></div>
        <!-- <b-row>
          <b-col><h1 class="buttonpad">Contractor's Time</h1></b-col>
          <b-col class="align-right">
            <b-button @click="approveAllTimeSheets()" variant="primary"
              >Approve All</b-button
            >
          </b-col>
        </b-row> -->
        <b-row>
          <b-col><h1 class="buttonpad">Contractor's Time</h1></b-col>
          <b-col class="align-right"> </b-col>
        </b-row>

        <hr />
        <b-card class="timeSheetcard">
          <b-row style="height: 300px; overflow: auto">
            <b-col cols="12" style="color: black">
              <b-row
                class="header-data fixed-header"
                style="background-color: #777"
              >
                <!-- <b-col cols="2"><h2>Approval</h2></b-col> -->
                <b-col cols="2" @click="sortBy('activityDate')">
                  <h2>
                    Date
                    <i
                      v-if="sortKey === 'activityDate'"
                      class="fas fa-arrow-up"
                      :class="{ 'fa-rotate-180': sortDirection === -1 }"
                    ></i>
                  </h2>
                </b-col>
                <b-col cols="2"><h2>Project</h2></b-col>
                <b-col cols="1">
                  <h2>Hrs</h2>
                </b-col>
                <b-col cols="2" @click="sortBy('name')"
                  ><h2>
                    Name
                    <i
                      v-if="sortKey === 'name'"
                      class="fas fa-arrow-up"
                      :class="{ 'fa-rotate-180': sortDirection === -1 }"
                    ></i></h2
                ></b-col>

                <b-col cols="4"><h2>Activity Comment</h2></b-col>
                <b-col sm="1"><h2>Actions</h2></b-col>
              </b-row>
              <b-row
                :key="index"
                v-for="(timesheet, index) in filteredTimesheets"
                class="data-table-row"
              >
                <!-- <b-col class="text-center" cols="2">
                  <input
                    type="checkbox"
                    v-model="timesheet.isApproved"
                    class="custom-checkbox"
                  />
                </b-col> -->
                <b-col class="text-center" cols="2">
                  {{ formatDate(timesheet.activityDate) }}
                </b-col>
                <b-col class="text-center" cols="2">
                  {{
                    filter.project && filter.project === 45
                      ? timesheet.subProjectId
                        ? projects.find(
                            (project) =>
                              project.projectID === timesheet.subProjectId
                          ).ProjectName
                        : timesheet.projectName
                      : timesheet.projectName
                  }}
                </b-col>
                <b-col class="text-center" cols="1">
                  {{ timesheet.activityTime }}
                </b-col>
                <b-col class="text-center" cols="2">
                  {{ timesheet.name }}
                </b-col>
                <b-col class="text-center" cols="4">
                  {{ timesheet.activity }}
                </b-col>
                <b-col class="text-center" sm="1">
                  <i
                    title="Edit"
                    @click="editTime(timesheet)"
                    class="fa fa-pencil text-dark"
                  ></i>
                </b-col>
              </b-row>
              <b-row class="footer-data">
                <b-col cols="4">
                  <h2>Total Time</h2>
                </b-col>
                <!-- <b-col cols="1"></b-col> -->
                <b-col class="text-center" cols="1">
                  <h2>{{ timesheetTotalHrs }}</h2>
                </b-col>
                <b-col cols="3"></b-col>
                <b-col cols="2"></b-col>
                <b-col sm="1"></b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <div class="spacer"></div>

        <b-card class="timeSheetcard">
          <b-row>
            <b-col><h1 class="buttonpad">Contractor's Expenses</h1></b-col>
            <!-- <b-col id="ShowAttachedBtn" cols="3">
              <b-col class="align-right">
                <b-button @click="ShowAttachedFiles()" variant="primary"
                  >Show attached files</b-button
                >
              </b-col>
            </b-col> -->
            <b-col id="ShowAttachedBtn" cols="3">
              <b-col class="align-right">
                <b-button @click="approveExpenses()" variant="primary"
                  >Approve All Expenses</b-button
                >
              </b-col>
            </b-col>

            <b-col id="ShowAttachedBtn" cols="3">
              <b-col class="align-right">
                <b-button
                  @click="approveSelectedExpenses()"
                  variant="primary"
                  style="width: 250px"
                  >Approve Selected Expenses</b-button
                >
              </b-col>
            </b-col>

            <b-col class="align-right">
              <b-button @click="downloadExpenses()" variant="primary"
                >Download All Expenses</b-button
              >
            </b-col>
          </b-row>
          <hr />
          <b-row style="height: 300px; overflow: auto">
            <b-col>
              <!-- Table Header-->
              <b-row
                class="header-data fixed-header"
                style="background-color: #777"
              >
                <!-- <b-col style="width: 25%"><h2>Approval</h2></b-col> -->
                <b-col @click="sortBy2('expenseDate')">
                  <h2>
                    Date
                    <i
                      v-if="sortKey === 'expenseDate'"
                      class="fas fa-arrow-up"
                      :class="{ 'fa-rotate-180': sortDirection === -1 }"
                    ></i>
                  </h2>
                </b-col>
                <b-col><h2>Project</h2></b-col>
                <b-col><h2>Project Currency</h2></b-col>
                <b-col><h2>Expense Currency</h2></b-col>
                <b-col><h2 class="text-center">Amount</h2></b-col>
                <b-col @click="sortBy2('name')"
                  ><h2>
                    Name
                    <i
                      v-if="sortKey === 'name2'"
                      class="fas fa-arrow-up"
                      :class="{ 'fa-rotate-180': sortDirection === -1 }"
                    ></i></h2
                ></b-col>
                <b-col><h2 class="text-center">File Name</h2></b-col>
                <b-col><h2>Comments</h2></b-col>
                <b-col sm="1"><h2>Actions</h2></b-col>
                <b-col sm="1"><h2>Approve</h2></b-col>
              </b-row>
              <!-- Table Data-->
              <b-row
                :key="index"
                v-for="(expense, index) in filteredExpenses"
                class="data-table-row"
                id="sortedItems"
              >
                <!-- <b-col class="text-center">
                  <input
                    type="checkbox"
                    v-model="expense.isApproved"
                    class="custom-checkbox"
                  />
                </b-col> -->
                <b-col class="text-center">
                  {{ formatDate(expense.expenseDate) }}
                </b-col>
                <b-col class="text-center">
                  {{ expense.projectName }}
                </b-col>
                <b-col class="text-center">
                  {{ expense.projectCurrency }}
                </b-col>
                <b-col class="text-center">
                  {{ expense.currency }}
                </b-col>
                <b-col class="text-center">
                  ({{ expense.projectCurrency }})
                  {{ formatPrice(expense.amount * expense.currencyRate) }}
                </b-col>
                <b-col class="text-center">
                  {{ expense.name }}
                </b-col>
                <!-- <b-col class="text-center" style="left: 2.5rem" cols="2">
                  <span style="font-size: 10px;" v-if="expense.uploadedLink">{{ extractFilename(expense.uploadedLink) }}</span>
                  <span v-else>-</span>
                </b-col> -->

                <b-col class="text-center">
                  <div v-if="expense.uploadedLink">
                    <a
                      style="color: black"
                      :href="`https://myerpfuncstorage.blob.core.windows.net/attachment-files-prod/${expense.uploadedLink}`"
                      target="_blank"
                    >
                      <i
                        :id="`popover-1-${expense.id}-link`"
                        class="fa fa-file-pdf-o"
                      ></i>
                    </a>

                    <b-popover
                      :target="`popover-1-${expense.id}-link`"
                      title="File Name"
                      triggers="hover focus"
                      :content="`${extractFilename(expense.uploadedLink)}`"
                    ></b-popover>
                  </div>
                  <span v-else> - </span>
                </b-col>

                <b-col class="text-center">
                  <div v-if="expense.comment">
                    <i
                      :id="`popover-1-${expense.id}`"
                      class="fa fa-comment-alt"
                    ></i>

                    <b-popover
                      :target="`popover-1-${expense.id}`"
                      title="Comment"
                      triggers="hover focus"
                      :content="`${expense.comment}`"
                    ></b-popover>
                  </div>
                  <span v-else> - </span>
                </b-col>
                <b-col class="text-center" sm="1">
                  <i
                    title="Edit"
                    class="fa fa-pencil text-dark"
                    @click="editExpense(expense)"
                  ></i>
                </b-col>
                <b-col class="text-center" sm="1">
                  <div v-if="expense.isApproved">
                    <input type="checkbox" :value="expense.id" checked />
                  </div>
                  <div v-else>
                    <input
                      type="checkbox"
                      ref="approveCheckbox"
                      :value="expense.id"
                      @click="selectedExpenseForApproval"
                    />
                  </div>
                </b-col>
              </b-row>
              <b-row class="footer-data">
                <b-col><h2>Total Expenses</h2></b-col>
                <b-col></b-col>
                <b-col></b-col>
                <!-- <b-col></b-col> -->
                <b-col class="text-center" style="margin-left: 2%">
                  <h2>{{ formatPrice(expenseTotal) }}</h2>
                </b-col>
                <b-col></b-col>
                <b-col></b-col>
                <b-col></b-col>
                <b-col sm="1"></b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <div class="spacer"></div>

        <div id="myimg"></div>

        <!-- <b-card>
          <b-row>
            <b-col cols="3"></b-col>
            <b-col cols="6">
              <h1>Summary</h1>
              <b-row>
                <b-col class="summary-body">
                  <b-row class="summary-row">
                    <b-col> Time: </b-col>
                    <b-col class="text-right">
                      $ {{ formatPrice(timesheetTotal) }}
                    </b-col>
                  </b-row>
                  <b-row class="summary-row">
                    <b-col> Expenses: </b-col>
                    <b-col class="text-right">
                      $ {{ formatPrice(expenseTotal) }}
                    </b-col>
                  </b-row>
                  <b-row class="summary-total">
                    <b-col> TOTAL: </b-col>
                    <b-col class="text-right">
                      $ {{ formatPrice(contractorTotal) }}
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row> -->
        <!--<b-col class="align-left buttonpad">
               <b-button variant="primary" @click="getReceipts">Get Receipts</b-button>
            </b-col>-->
        <!-- <b-col class="align-right buttonpad">
                  <b-button variant="primary" @click="saveData"
                    >Accept Invoice</b-button
                  >
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="3"></b-col>
          </b-row>
        </b-card> -->
      </div>
    </b-container>
    <div>
      <b-modal
        hide-footer
        centered
        title="Edit Timesheet"
        id="edit-time"
        body-class="p-0"
      >
        <div class="d-block text-center p-3">
          <div class="form-row">
            <div class="form-group col-12">
              <span class="float-left">Project:</span>
              <select class="form-control" v-model="selectedRecord.projectId">
                <option disabled selected style="display: none"></option>
                <option
                  :key="index"
                  v-for="(project, index) in projects"
                  :value="project.projectID"
                >
                  {{ project.ProjectName }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-row" v-if="selectedRecord.projectId === 45">
            <div class="form-group col-12">
              <span class="float-left">Sub Project:</span>
              <select
                class="form-control"
                v-model="selectedRecord.subProjectId"
              >
                <option disabled selected style="display: none"></option>
                <option
                  :key="index"
                  v-for="(project, index) in projects.filter(
                    (project) => project.projectID !== 45
                  )"
                  :value="project.projectID"
                >
                  {{ project.ProjectName }}
                </option>
              </select>
            </div>
          </div>

          <span class="float-left">Hours:</span>
          <b-form-input
            v-model="selectedRecord.activityTime"
            type="number"
            step="0.1"
            placeholder="Add Hours"
          ></b-form-input>
          <br />
          <span class="float-left">Activity Comment:</span>
          <b-form-input
            v-model="selectedRecord.activity"
            type="text"
          ></b-form-input>
          <br />
          <span class="float-left">Activity Date:</span>
          <b-form-input
            v-model="selectedRecord.activityDate"
            type="date"
          ></b-form-input>
          <br />
          <!-- <div class="form-group col-12">
            <span class="float-left">Task Performed:</span>
            <b-input-group class="mt-3">
              <b-form-input
                id="Activity"
                v-model="selectedRecord.activity"
                type="text"
                :maxlength="50"
                name="Activity"
                placeholder="Add Task you performed"
              ></b-form-input>
            </b-input-group>
          </div>

          <div class="form-group col-12">
            <span class="float-left">Activity Date:</span>
            <b-input-group class="mt-3">
              <b-form-input
                id="ActivityDate"
                v-model="selectedRecord.activityDate"
                type="date"
                :maxlength="50"
                name="Activity"
                placeholder="Add Task you performed"
              ></b-form-input>
            </b-input-group>
          </div> -->
          <!-- <span class="float-left">activity Comment:</span>
          <b-form-input
            v-model="selectedRecord.activityComment"
            type="text"
          ></b-form-input> -->
        </div>
        <hr class="m-0" />
        <div class="p-3">
          <b-button variant="outline-danger" @click="$bvModal.hide('edit-time')"
            >Close</b-button
          >
          <b-button class="ml-3" variant="outline-primary" @click="updateTime"
            >Save Changes</b-button
          >
        </div>
      </b-modal>
      <b-modal
        hide-footer
        centered
        title="Edit Expense"
        id="edit-expense"
        body-class="p-0"
      >
        <div class="d-block text-center p-3">
          <!-- <div class="form-row">
            <div class="form-group col-12">
              <span class="float-left">Project:</span>
              <select class="form-control" v-model="selectedRecord.projectId">
                <option
                  :key="project.ProjectID"
                  v-for="project in projects"
                  :value="project.ProjectID"
                >
                  {{ project.ProjectName }}
                </option>
              </select>
            </div>
          </div> -->
          <div class="form-row">
            <div class="form-group col-12">
              <span class="float-left">Expense Type</span>
              <select class="form-control" v-model="selectedRecord.categoryId">
                <option disabled selected style="display: none"></option>
                <option
                  :key="index"
                  v-for="(project, index) in expenseTypes"
                  :value="project.Id"
                >
                  {{ project.Name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12 text-left">
              <label for="billableCheck">Not Billable</label>

              <input
                type="checkbox"
                value="1"
                id="billableCheck"
                ref="billableCheck"
                :checked="selectedRecord.isBillable"
                style="width: 16px; height: 16px; margin-left: 10px"
              />
            </div>
          </div>
          <!-- <div class="form-row">
            <div v-if="selectedRecord.categoryId === 3" class="form-group col-12">
              <span class="float-left"
                >Departure Date</span
              >
              <b-form-input
                v-model="selectedRecord.expenseDate"
                type="date"
              ></b-form-input>
            </div>
            <div v-else-if="selectedRecord.categoryId === 1" class="form-group col-12">
              <span class="float-left"
                >Check-In Date</span
              >
              <b-form-input
                v-model="selectedRecord.expenseDate"
                type="date"
              ></b-form-input>
            </div>
            <div v-else class="form-group col-12">
              <span class="float-left"
                >Expense Date</span
              >
              <b-form-input
                v-model="selectedRecord.expenseDate"
                type="date"
              ></b-form-input>
            </div>
          </div> -->
          <!-- <div class="form-row">
            <div class="form-group col-12">
              <span class="float-left"
                >Amount ({{ (selectedRecord.currency || "").trim() }})</span
              >
              <b-form-input
                v-model="selectedRecord.amount"
                type="number"
                step="0.01"
                placeholder="Add Hours"
              ></b-form-input>
            </div>
          </div> -->

          <div class="form-row">
            <div class="form-group col-12 text-left" ref="expenseDateLabel">
              <label>Date of Expense</label>
            </div>
            <div class="form-group col-12 d-flex justify-content-center">
              <div style="width: 75%">
                <vc-date-picker
                  class="calendar"
                  is-expanded
                  mode="date"
                  timezone="UTC"
                  :min-date="min_date"
                  :max-date="max_date"
                  v-model="selectedRecord.expenseDate"
                />
              </div>
              <CurrencyCalculator
                ref="currencyCalculator"
                :targetCurrency="selectedRecord.projectCurrency"
                :expenseAmount="selectedRecord.amount"
                :receiptCurrency="selectedRecord.currency"
                :expenseDate="selectedRecord.expenseDate"
                :expense-date="selectedRecord.expenseDate"
                @currency-converted="updateCurrencyRate"
              />
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-12">
              <span class="float-left"
                >Amount ({{ (selectedRecord.currency || "").trim() }})</span
              >
              <b-form-input
                v-model="selectedRecord.amount"
                type="number"
                step="0.01"
                placeholder="Add Hours"
              ></b-form-input>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <span class="float-left"
                >Currency rate: {{ selectedRecord.currencyRate }}</span
              >
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-12">
              <span class="float-left"
                >Expense Claimed:
                {{
                  formatPrice(
                    selectedRecord.amount * selectedRecord.currencyRate
                  )
                }}
                ({{ selectedRecord.projectCurrency }})</span
              >
            </div>
          </div>
          <span class="float-left">Comments (Required):</span>
          <b-textarea
            rows="4"
            v-model="selectedRecord.comment"
            placeholder="Edit your comments here..."
          ></b-textarea>
        </div>
        <hr class="m-0" />
        <div class="p-3">
          <b-button
            variant="outline-danger"
            @click="$bvModal.hide('edit-expense')"
            >Close</b-button
          >
          <b-button
            class="ml-3"
            variant="outline-primary"
            @click="updateExpense"
            >Save Changes</b-button
          >
        </div>
      </b-modal>
      <b-modal
        hide-footer
        hide-header
        id="export-pbi"
        body-class="p-0"
        class="modal-centered"
      >
        <div class="modal-content">
          <section id="container"></section>
        </div>
        <!-- Rest of the modal content -->
      </b-modal>

      <b-modal
        hide-footer
        centered
        title="Timesheet Download"
        id="timesheet-download"
        body-class="p-0"
      >
        <div class="d-block text-center p-3">
          <div class="form-row">
            <div class="form-group col-12">
              <span class="float-left">Timesheet Type:</span>
              <select class="form-control" ref="userSelectedBillableType">
                <option :value="null" style="display: none">
                  Please choose...
                </option>
                <option value="false" selected>Client Timesheet</option>
                <option
                  :key="index"
                  v-for="(isBillable, index) in expenseTypeProjects"
                  :value="isBillable.isBillable"
                >
                  Insight-AWP Non Billable
                </option>
                <option v-if="showOTTimesheet" value="OT">OT Timesheet</option>
              </select>
              <div
                v-if="validateBillable"
                id="projectValidation"
                style="color: red"
              >
                Type is required.
              </div>
            </div>
          </div>
        </div>
        <hr class="m-0" />
        <div class="p-3">
          <b-button
            variant="outline-danger"
            @click="$bvModal.hide('timesheet-download')"
            >Close</b-button
          >
          <b-button
            class="ml-3"
            variant="outline-primary"
            @click="downloadTimesheetModal"
            >View Report</b-button
          >
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import TimePeriod from "../core/TimePeriod";
import * as pbi from "powerbi-client";
import TimeSheetData from "../core/TimeSheetData";
import FormatUtilities from "../utilities/FormatUtilities";
import ExpenseData from "../core/ExpenseData";
import { extractError } from "../utilities/ErrorUtilities";
import { mapGetters } from "vuex";
import CurrencyCalculator from "@/components/CurrencyCalculator.vue";

export default {
  data() {
    return {
      filter: {},
      periods: [],
      projects: [],
      filteredTimesheets: [],
      users: [],
      timesheets: [],
      expenses: [],
      filteredExpenses: [],
      selectedRecord: {},
      expenseTypes: [],
      selectedExpenses: [],
      expenseTypeProjects: [],
      validateProject: false,
      validateBillable: false,
      sortKey: "",
      sortDirection: 1,
      showOTTimesheet: false
    };
  },
  // props: {
  //   expenseTypes: Array
  // },
  components: {
    CurrencyCalculator
  },

  computed: {
    ...mapGetters({
      currentUser: "getLoggedInUser"
    }),
    filteredUsers() {
      if (!this.filter.owner) {
        return this.users;
      }
      return this.users.filter((user, index) => {
        user.originalIndex = index;
        return user.UserOwnerID == this.filter.owner;
      });
    },
    timesheetTotalHrs() {
      let timesheetTotalHrs = 0.0;
      for (let i = 0; i < this.timesheets.length; i++) {
        timesheetTotalHrs += this.timesheets[i].activityTime;
      }
      return timesheetTotalHrs;
    },

    expenseTotal() {
      let expenseTotal = 0.0;
      for (let i = 0; i < this.expenses.length; i++) {
        expenseTotal += this.expenses[i].amount * this.expenses[i].currencyRate;
      }
      return expenseTotal;
    },
    timesheetTotal() {
      let timesheetTotal = 0.0;
      timesheetTotal = this.timesheetTotalHrs * 200;
      return (Math.trunc(timesheetTotal * 100) / 100).toFixed(2);
    },
    contractorTotal() {
      let contractorTotal = 0.0;
      contractorTotal = eval(this.timesheetTotal) + eval(this.expenseTotal);
      return contractorTotal;
    },
    formattedDate() {
      const isoDate = this.selectedRecord.expenseDate;
      const date = isoDate.substr(0, 10); // extract YYYY-MM-DD from the ISO string
      return date;
    },
    min_date() {
      return this.$moment()
        .utc()
        .subtract(6, "months")
        .startOf("month")
        .format("YYYY/MM/DD");
    },
    max_date() {
      return this.$moment()
        .utc()
        .add(2, "months")
        .endOf("month")
        .format("YYYY/MM/DD");
    }
  },

  created() {
    this.setLoading(true);
    this.loadEmployees().then(() => {
      this.setLoading(false);
      this.loadProjects();
      this.loadExpenseTypes();
    });
  },

  methods: {
    checkOwnerID() {
    const selectedProject = this.projects.find(
      (project) => project.projectID === this.filter.project
    );
    const selectedSubProject = this.projects.find(
      (project) => project.projectID === this.filter.subProject
    );

    if (
      (selectedProject && (selectedProject.OwnerID === 2 || selectedProject.ProjectName === 'PS - Wolf Hollow' || selectedProject.ProjectName === 'PS - Project Moat')) ||
      (selectedSubProject && (selectedSubProject.OwnerID === 2 || selectedSubProject.ProjectName === 'PS - Wolf Hollow' || selectedSubProject.ProjectName === 'PS - Project Moat'))
    ) {
      this.showOTTimesheet = true;
    } else {
      this.showOTTimesheet = false;
    }
  },
    updateCurrencyRate({ currencyRate }) {
      this.selectedRecord.currencyRate = currencyRate;
    },
    async handleExpenseDateChange(newDate, oldDate) {
      await this.$nextTick();
      if (this.$refs.currencyCalculator) {
        console.log("Sending to CurrencyCalculator:");
        console.log("targetCurrency:", this.selectedRecord.projectCurrency);
        console.log("expenseAmount:", this.selectedRecord.amount);
        console.log("receiptCurrency:", this.selectedRecord.currency);
        console.log("expenseDate:", this.selectedRecord.expenseDate);
        await this.$refs.currencyCalculator.loadRapidRates();
      } else {
        console.error("CurrencyCalculator component is not loaded.");
      }
    },
    formatDate: FormatUtilities.formatDate,
    formatPrice: FormatUtilities.formatPrice,

    async ShowAttachedFiles() {
      if (isNaN(this.filter.project)) {
        this.makeToast("danger", "Please select the project.");

        return;
      }

      try {
        this.setLoading(true);
        const periodData = await this.$axios.get(`/expenses/attachments`, {
          responseType: "array",
          params: {
            period: this.filter.date_range,
            project: this.filter.project,
            user_id: this.users[this.filter.user].CreatedBy
          }
        });
        console.log(periodData);
        this.showFile(periodData.data || null);
        //this.printFile();
      } catch (err) {
        console.error(err);
        if (err.response.status === 404) {
          this.makeToast(
            "danger",
            "Files do not exist for current selection, please try modifying the filters."
          );
        }
      } finally {
        this.setLoading(false);
      }
    },

    getUppercaseValue(value) {
      if (typeof value === "string") {
        return value.toUpperCase();
      }
      return String(value).toUpperCase();
    },

    sortBy(key) {
      if (key === this.sortKey) {
        this.sortDirection = -this.sortDirection; // toggle direction
      } else {
        this.sortKey = key;
        this.sortDirection = 1; // set default direction
      }

      // sort the data by the selected key and direction
      this.filteredTimesheets.sort((a, b) => {
        let x = a[key];
        let y = b[key];

        if (typeof x === "string") {
          x = x.toLowerCase();
          y = y.toLowerCase();
        }

        return this.sortDirection * ((x > y) - (y > x));
      });
    },
    sortBy2(key) {
      if (key === "name") {
        key = "name2";
      }
      if (key === this.sortKey) {
        this.sortDirection = -this.sortDirection; // toggle direction
      } else {
        this.sortKey = key;
        this.sortDirection = 1; // set default direction
      }

      // sort the data by the selected key and direction
      this.filteredExpenses.sort((a, b) => {
        if (key === "name2") {
          key = "name";
        }
        let x = a[key];
        let y = b[key];

        if (typeof x === "string") {
          x = x.toLowerCase();
          y = y.toLowerCase();
        }

        return this.sortDirection * ((x > y) - (y > x));
      });
    },
    extractFilename(url) {
      const parts = url.split("/");
      return parts[parts.length - 1];
    },
    async approveExpenses() {
      console.log("in approve Expenses");
      //if()
      console.log(this.filter.user);
      let userId = null;
      if (this.filter.user !== undefined && this.filter.user !== null) {
        userId = this.users[this.filter.user].CreatedBy;
      }
      const period = this.filter.date_range;
      const project_id = this.projects[this.filter.project].projectID;
      await this.$axios
        .post("/approveExpenses", {
          userId: userId,
          period: period,
          projectId: project_id
        })
        .then((response) => {
          console.log(response.data.message);
          if (response.data.message == "Success") {
            this.makeToast("success", "Expenses Approved Successfully");
            this.fetchData();
          } else {
            this.makeToast("danger", "Something went Wrong");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async approveSelectedExpenses() {
      console.log("in approve Expenses");
      //if()
      await this.$axios
        .post("/approveSelectedExpenses", {
          selectedExpenses: this.selectedExpenses
        })
        .then((response) => {
          console.log(response.data.message);
          if (response.data.message == "Success") {
            this.makeToast("success", "Expenses Approved Successfully");
            this.fetchData();
          } else {
            this.makeToast("danger", "Something went Wrong");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async selectedExpenseForApproval() {
      // if(this.$refs.approveCheckbox[0].checked){
      //   this.selectedExpenses.push(this.$refs.approveCheckbox[1].value)
      //   console.log(this.selectedExpenses)
      // }
      for (let i = 0; i < this.$refs.approveCheckbox.length; i++) {
        if (this.$refs.approveCheckbox[i].checked) {
          if (
            this.selectedExpenses.indexOf(
              this.$refs.approveCheckbox[i].value
            ) === -1
          ) {
            this.selectedExpenses.push(this.$refs.approveCheckbox[i].value);
            //console.log(this.selectedExpenses)
          }
        }
      }
    },

    async downloadTimesheets() {
      console.log("here");
      this.checkOwnerID();
      let parmProject = "";
      let parmDate = "";
      let parmUser = "";
      try {
        parmUser = this.users[this.$refs.fieldsett.value].Name;
      } catch (err) {
        parmUser = "";
      }

      try {
        if (parmUser === "") {
          parmUser = "All";
        }
        parmProject = this.projects.find(
          (project) => project.projectID === this.$refs.fieldsetProject.value
        ).ProjectName;
        this.expenseTypeProjects = [];

        this.$bvModal.show("timesheet-download");
        const period = this.filter.date_range;
        let project_id = 0;
        try {
          project_id = this.projects[this.filter.project].projectID;
        } catch (err) {
          project_id = 0;
        }
        let user_id = 0;
        try {
          user_id = this.users[this.filter.user].CreatedBy;
        } catch (err) {
          user_id = 0;
        }

        const expenseTypeData = await this.$axios.get(`/getExpenseType`, {
          params: {
            period,
            project_id,
            user_id
          }
        });
        console.log(expenseTypeData);
        this.expenseTypeProjects = expenseTypeData.data;
      } catch (err) {
        console.log(err);
        parmProject = "";
        this.validateProject = true;
      }
    },

    async downloadTimesheetModal() {
      //console.log(this.$refs.userSelectedBillableType.value);
      //console.log(this.$refs.periodOfTimesheets.value)
      if (this.$refs.userSelectedBillableType.value === "") {
        this.validateBillable = true;
      } else if (this.$refs.userSelectedBillableType.value === "false") {
        this.validateBillable = false;
        this.$bvModal.hide("timesheet-download");
        let parmProject = "";
        let parmDate = "";
        let parmUser = "";
        let monthType = "";
        let button = document.getElementById("downloadTimesheetsFromPowerBi");
        for (let i = 0; i < this.periods.length; i++) {
          if (this.$refs.periodOfTimesheets.value == this.periods[i].key) {
            parmDate = this.periods[i].value;
            let index = parmDate.lastIndexOf(" ");

            if (parmDate.substring(0, parmDate.indexOf(" ")) === "Dec") {
              parmDate = "December " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Jan") {
              parmDate = "January " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Feb") {
              parmDate = "February " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Mar") {
              parmDate = "March " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Apr") {
              parmDate = "April " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "May") {
              parmDate = "May " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Jun") {
              parmDate = "June " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Jul") {
              parmDate = "July " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Aug") {
              parmDate = "August " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Sep") {
              parmDate = "September " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Oct") {
              parmDate = "October " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Nov") {
              parmDate = "November " + parmDate.substring(index + 1);
            }
          }
        }
        try {
          parmUser = this.users[this.$refs.fieldsett.value].Name;
        } catch (err) {
          parmUser = "";
        }

        try {
          if (parmUser === "") {
            parmUser = "All";
          }
          parmProject = this.projects.find(
            (project) => project.projectID === this.$refs.fieldsetProject.value
          ).ProjectName;
          if (
            this.projects.find(
              (project) =>
                project.projectID === this.$refs.fieldsetProject.value
            ).BillingCycle === "Fixed Offset (26 to 25)"
          ) {
            monthType = "26 Prev - 25 Curr Month";
          } else if (
            this.projects.find(
              (project) =>
                project.projectID === this.$refs.fieldsetProject.value
            ).BillingCycle === "Standard Month (First to last)"
          ) {
            monthType = "Standard Month";
          } else {
            monthType = "Week Starting Saturday";
          }

          if (parmProject !== "Insight-AWP (CAD)") {
            let embedUrl = `https://app.powerbi.com/rdlEmbed?reportId=26b22f86-1e72-4430-abfe-9e7dbd4852b2&rp:parmProjects=${parmProject}&rp:parmDate=${parmDate}&rp:parmUser=${parmUser}&rp:parmDatesType=${monthType}&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d`;
            this.$bvModal.show("export-pbi");
            this.setLoading(true);
            await this.$axios
              .post("/getPBIEmbed", {
                reportId: "26b22f86-1e72-4430-abfe-9e7dbd4852b2"
              })
              .then((response) => {
                console.log(response.data);
                this.setLoading(false);
                this.accessToken = response.data.body.token;
                const permissions = pbi.models.Permissions.All;

                const config = {
                  type: "report",
                  tokenType: pbi.models.TokenType.Embed,
                  accessToken: this.accessToken,
                  embedUrl: embedUrl,
                  settings: {
                    panes: {
                      filters: {
                        visible: false
                      },
                      pageNavigation: {
                        visible: false
                      }
                    }
                  },
                  permissions: permissions
                };

                let powerbi = new pbi.service.Service(
                  pbi.factories.hpmFactory,
                  pbi.factories.wpmpFactory,
                  pbi.factories.routerFactory
                );

                const dashboardContainer = document.getElementById("container");
                const dashboard = powerbi.embed(dashboardContainer, config);
                const container = document.getElementById("container");
                const iframe = container.querySelector("iframe");

                iframe.onload = function () {
                  const iframeDocument =
                    iframe.contentDocument || iframe.contentWindow.document;
                  console.log(iframeDocument); // Access the #document of the iframe here

                  // Perform any CSS manipulation or other operations on the iframeDocument
                };
                // if (elementToHide) {
                //   elementToHide.style.display = "none";
                // }
                // dashboard.off("loaded");
                // dashboard.off("rendered");
                // dashboard.on("error", function () {
                //   this.dashboard.off("error");
                // });
                dashboard.on("loaded", function () {
                  console.log("fjhewjhufe");
                  const elementToHide =
                    dashboardContainer.contentDocument.querySelector("#root");
                  console.log(elementToHide);
                  if (elementToHide) {
                    elementToHide.style.display = "none";
                  }
                });
              });
          } else {
            let embedUrl = `https://app.powerbi.com/rdlEmbed?reportId=373be30a-a875-40dd-b451-263fda50f166&rp:parmProjects=${parmProject}&rp:parmDate=${parmDate}&rp:parmUser=${parmUser}&rp:parmDatesType=${monthType}&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d`;
            this.$bvModal.show("export-pbi");
            this.setLoading(true);
            await this.$axios
              .post("/getPBIEmbed", {
                reportId: "373be30a-a875-40dd-b451-263fda50f166"
              })
              .then((response) => {
                console.log(response.data);
                this.setLoading(false);
                this.accessToken = response.data.body.token;
                const permissions = pbi.models.Permissions.All;

                const config = {
                  type: "report",
                  tokenType: pbi.models.TokenType.Embed,
                  accessToken: this.accessToken,
                  embedUrl: embedUrl,
                  settings: {
                    panes: {
                      filters: {
                        visible: false
                      },
                      pageNavigation: {
                        visible: false
                      }
                    }
                  },
                  permissions: permissions
                };

                let powerbi = new pbi.service.Service(
                  pbi.factories.hpmFactory,
                  pbi.factories.wpmpFactory,
                  pbi.factories.routerFactory
                );

                const dashboardContainer = document.getElementById("container");
                const dashboard = powerbi.embed(dashboardContainer, config);
                const container = document.getElementById("container");
                const iframe = container.querySelector("iframe");

                iframe.onload = function () {
                  const iframeDocument =
                    iframe.contentDocument || iframe.contentWindow.document;
                  console.log(iframeDocument); // Access the #document of the iframe here

                  // Perform any CSS manipulation or other operations on the iframeDocument
                };
                // if (elementToHide) {
                //   elementToHide.style.display = "none";
                // }
                // dashboard.off("loaded");
                // dashboard.off("rendered");
                // dashboard.on("error", function () {
                //   this.dashboard.off("error");
                // });
                dashboard.on("loaded", function () {
                  console.log("fjhewjhufe");
                  const elementToHide =
                    dashboardContainer.contentDocument.querySelector("#root");
                  console.log(elementToHide);
                  if (elementToHide) {
                    elementToHide.style.display = "none";
                  }
                });
              });
          }
        } catch (err) {
          console.log(err);
          parmProject = "";
          this.validateProject = true;
        }
      } else if (this.$refs.userSelectedBillableType.value === "true") {
        this.validateBillable = false;
        this.$bvModal.hide("timesheet-download");
        let parmProject = "";
        let parmDate = "";
        let parmUser = "";
        let button = document.getElementById("downloadTimesheetsFromPowerBi");
        for (let i = 0; i < this.periods.length; i++) {
          if (this.$refs.periodOfTimesheets.value == this.periods[i].key) {
            parmDate = this.periods[i].value;
            let index = parmDate.lastIndexOf(" ");

            if (parmDate.substring(0, parmDate.indexOf(" ")) === "Dec") {
              parmDate = "December " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Jan") {
              parmDate = "January " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Feb") {
              parmDate = "February " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Mar") {
              parmDate = "March " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Apr") {
              parmDate = "April " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "May") {
              parmDate = "May " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Jun") {
              parmDate = "June " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Jul") {
              parmDate = "July " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Aug") {
              parmDate = "August " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Sep") {
              parmDate = "September " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Oct") {
              parmDate = "October " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Nov") {
              parmDate = "November " + parmDate.substring(index + 1);
            }
          }
        }
        try {
          parmUser = this.users[this.$refs.fieldsett.value].Name;
        } catch (err) {
          parmUser = "";
        }

        try {
          if (parmUser === "") {
            parmUser = "All";
          }
          parmProject = this.projects.find(
            (project) => project.projectID === this.$refs.fieldsetProject.value
          ).ProjectName;
          let embedUrl = `https://app.powerbi.com/rdlEmbed?reportId=1a9094a2-a146-4ae2-b05f-50a2664239b3&rp:parmProjects=${parmProject}&rp:parmDate=${parmDate}&rp:parmUser=${parmUser}&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d`;
          this.$bvModal.show("export-pbi");
          this.setLoading(true);
          await this.$axios
            .post("/getPBIEmbed", {
              reportId: "1a9094a2-a146-4ae2-b05f-50a2664239b3"
            })
            .then((response) => {
              console.log(response.data);
              this.setLoading(false);
              this.accessToken = response.data.body.token;
              const permissions = pbi.models.Permissions.All;

              const config = {
                type: "report",
                tokenType: pbi.models.TokenType.Embed,
                accessToken: this.accessToken,
                embedUrl: embedUrl,
                settings: {
                  panes: {
                    filters: {
                      visible: false
                    },
                    pageNavigation: {
                      visible: false
                    }
                  }
                },
                permissions: permissions
              };

              let powerbi = new pbi.service.Service(
                pbi.factories.hpmFactory,
                pbi.factories.wpmpFactory,
                pbi.factories.routerFactory
              );

              const dashboardContainer = document.getElementById("container");
              const dashboard = powerbi.embed(dashboardContainer, config);
              const container = document.getElementById("container");
              const iframe = container.querySelector("iframe");

              iframe.onload = function () {
                const iframeDocument =
                  iframe.contentDocument || iframe.contentWindow.document;
                console.log(iframeDocument); // Access the #document of the iframe here

                // Perform any CSS manipulation or other operations on the iframeDocument
              };
              // if (elementToHide) {
              //   elementToHide.style.display = "none";
              // }
              // dashboard.off("loaded");
              // dashboard.off("rendered");
              // dashboard.on("error", function () {
              //   this.dashboard.off("error");
              // });
              dashboard.on("loaded", function () {
                console.log("fjhewjhufe");
                const elementToHide =
                  dashboardContainer.contentDocument.querySelector("#root");
                console.log(elementToHide);
                if (elementToHide) {
                  elementToHide.style.display = "none";
                }
              });
            });
        } catch (err) {
          console.log(err);
          parmProject = "";
          this.validateProject = true;
        }
      } else if (this.$refs.userSelectedBillableType.value === "OT") {
        this.validateBillable = false;
        this.$bvModal.hide("timesheet-download");
        let parmProject = "";
        let parmDate = "";
        let parmUser = "";
        let button = document.getElementById("downloadTimesheetsFromPowerBi");
        for (let i = 0; i < this.periods.length; i++) {
          if (this.$refs.periodOfTimesheets.value == this.periods[i].key) {
            parmDate = this.periods[i].value;
            let index = parmDate.lastIndexOf(" ");

            if (parmDate.substring(0, parmDate.indexOf(" ")) === "Dec") {
              parmDate = "December " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Jan") {
              parmDate = "January " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Feb") {
              parmDate = "February " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Mar") {
              parmDate = "March " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Apr") {
              parmDate = "April " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "May") {
              parmDate = "May " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Jun") {
              parmDate = "June " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Jul") {
              parmDate = "July " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Aug") {
              parmDate = "August " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Sep") {
              parmDate = "September " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Oct") {
              parmDate = "October " + parmDate.substring(index + 1);
            } else if (parmDate.substring(0, parmDate.indexOf(" ")) === "Nov") {
              parmDate = "November " + parmDate.substring(index + 1);
            }
          }
        }
        try {
          parmUser = this.users[this.$refs.fieldsett.value].Name;
        } catch (err) {
          parmUser = "";
        }

        try {
          if (parmUser === "") {
            parmUser = "All";
          }
          parmProject = this.projects.find(
            (project) => project.projectID === this.$refs.fieldsetProject.value
          ).ProjectName;
          let embedUrl = `https://app.powerbi.com/rdlEmbed?reportId=f5139316-e9b5-45b9-9347-6c6495eb4498&rp:parmProjects=${parmProject}&rp:parmDate=${parmDate}&rp:parmUser=${parmUser}&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUNFTlRSQUwtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d`;
          this.$bvModal.show("export-pbi");
          this.setLoading(true);
          await this.$axios
            .post("/getPBIEmbed", {
              reportId: "f5139316-e9b5-45b9-9347-6c6495eb4498"
            })
            .then((response) => {
              console.log(response.data);
              this.setLoading(false);
              this.accessToken = response.data.body.token;
              const permissions = pbi.models.Permissions.All;

              const config = {
                type: "report",
                tokenType: pbi.models.TokenType.Embed,
                accessToken: this.accessToken,
                embedUrl: embedUrl,
                settings: {
                  panes: {
                    filters: {
                      visible: false
                    },
                    pageNavigation: {
                      visible: false
                    }
                  }
                },
                permissions: permissions
              };

              let powerbi = new pbi.service.Service(
                pbi.factories.hpmFactory,
                pbi.factories.wpmpFactory,
                pbi.factories.routerFactory
              );

              const dashboardContainer = document.getElementById("container");
              const dashboard = powerbi.embed(dashboardContainer, config);
              const container = document.getElementById("container");
              const iframe = container.querySelector("iframe");

              iframe.onload = function () {
                const iframeDocument =
                  iframe.contentDocument || iframe.contentWindow.document;
                console.log(iframeDocument); // Access the #document of the iframe here
              };
              dashboard.on("loaded", function () {
                console.log("fjhewjhufe");
                const elementToHide =
                  dashboardContainer.contentDocument.querySelector("#root");
                console.log(elementToHide);
                if (elementToHide) {
                  elementToHide.style.display = "none";
                }
              });
            });
        } catch (err) {
          console.log(err);
          parmProject = "";
          this.validateProject = true;
        }
      }
    },

    showFile(tempArray) {
      let imgCnt = 0;

      tempArray.forEach((file) => {
        let url = file;
        imgCnt += 1;

        var img = document.createElement("img");

        img.setAttribute("id", "img-" + imgCnt);
        //var img = document.getElementById("myimg");
        img.src = url;
        img.onload = function () {
          this.width = 300;
          document.body.appendChild(this);
        };
      });
    },

    hideFile() {
      const imgChk = document.getElementsByTagName("img");

      if (imgChk.length > 1) {
        let imgCnt = imgChk.length;
        for (let i = 1; i < imgCnt; i++) {
          const img = document.getElementById("img-" + i);
          img.remove();
        }
      }
    },

    async downloadExpenses() {
      try {
        console.log(this.filter.date_range);
        this.setLoading(true);
        let project_id = 0;
        try {
          project_id = this.filter.project;
          console.log(project_id);
        } catch (err) {
          project_id = 0;
        }
        let user_id = 0;
        try {
          user_id = this.users[this.filter.user].CreatedBy;
        } catch (err) {
          user_id = 0;
        }
        const periodData = await this.$axios.get("/expenses/download", {
          responseType: "blob",
          params: {
            period: this.filter.date_range,
            project: project_id,
            user_id: user_id
          }
        });
        console.log("Here");
        this.downloadFile(periodData.data || null, "expenses.zip");
      } catch (err) {
        console.error(err);
        if (err.response.status === 404) {
          this.makeToast(
            "danger",
            "Files do not exist for current selection, please try modifying the filters."
          );
        }
      } finally {
        this.setLoading(false);
      }
    },

    downloadFile(blob, filename = "file") {
      if (!blob) {
        return;
      }
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([blob]));
      link.download = filename;
      link.click();
    },

    /**
     * Sets isApprove to true,
     * on all of the given timesheets
     */
    approveAllTimeSheets() {
      for (let i = 0; i < this.timesheets.length; i++) {
        this.timesheets[i].isApproved = true;
      }
    },

    // resetPeriods(newVal) {
    //   this.periods = [];

    //   let selected_user = this.projects[newVal] || this.users[newVal];;
    //   // console.log()
    //   console.log(this.projects[newVal])
    //   if (!selected_user) {
    //     return;
    //   }

    //   try {
    //     let max_months = selected_user.diff <= 2 ? selected_user.diff : 2;

    //     let max_date = this.formatDate(selected_user.max_date);

    //     for (let month = max_months; month >= 0; month--) {
    //       let _month_year = this.$moment(max_date).subtract(month, "M");

    //       this.periods.push({
    //         key: `${_month_year.year()}-${_month_year.month()}`,
    //         value: _month_year.format("MMM - Y")
    //       });
    //     }
    //   } catch (err) {
    //     this.makeToast("danger", extractError(err));
    //   }
    // },

    resetPeriods(newVal) {
      console.log("resetPeriods");
      this.periods = [];

      // let selected_user =
      //   this.projects.some((p) => p.projectID === this.filter.project) ||
      //   this.users[newVal];
      // if (!selected_user) {
      //   return;
      // }

      try {
        let max_months_before = 6; // Display 2 months before
        let months_ahead = 1; // Display 1 month ahead

        for (let month = -max_months_before; month <= months_ahead; month++) {
          let _month_year = this.$moment().add(month, "M");

          this.periods.push({
            key: `${_month_year.year()}-${_month_year.month()}`,
            value: _month_year.format("MMM - Y")
          });
        }
        console.log("updated periods - ", this.periods);
      } catch (err) {
        this.makeToast("danger", extractError(err));
      }
    },

    filterProjects() {
      this.filteredExpenses = this.expenses;
      console.log(this.expenses);
      this.filteredTimesheets = this.timesheets;
      // if (!this.filter.project) {
      //   console.log("return")
      //   return;
      // }
      //console.log(this.users[this.filter.user].CreatedBy)//console.log(e.createdBy)
      console.log("user...",this.filter.user);
      if (this.filter.user !== undefined && this.filter.user !== null) {
        let filteredExpenses = this.expenses.filter(
          (e) => e.createdBy === this.users[this.filter.user].CreatedBy
        );

        console.log(filteredExpenses);
        let filteredTimesheets = this.timesheets.filter(
          (t) => t.createdBy === this.users[this.filter.user].CreatedBy
        );

        this.$set(this, "filteredExpenses", filteredExpenses);
        this.$set(this, "filteredTimesheets", filteredTimesheets);
      }
    },

    loadEmployees() {
      return new Promise((resolve, reject) => {
        try {
          this.$axios.get(`/employees?sort_by=Name`).then((res) => {
            if (res) {
              this.users = res.data;
              // this.users = this.users.filter((user) =>
              //   user.UserName.includes(this.currentUser.userType)
              // );
              console.log(this.users);
              //console.log(this.users[0].CreatedBy)
              resolve();
            }
          });
        } catch (err) {
          reject();
        }
      });
    },

    async getReceipts() {},
    // saveData() {
    //   try {
    //     this.setLoading(true);

    //     this.$axios.post(
    //       `/timesheets`,
    //       this.timesheets.map((r) => r.toApi())
    //     );
    //     this.$axios
    //       .post(
    //         `/expenses`,
    //         this.expenses.map((r) => r.toApi())
    //       )
    //       .then(() => {
    //         this.fetchData();
    //       });
    //   } catch (err) {
    //     this.makeToast("danger", extractError(err));
    //   } finally {
    //     this.setLoading(false);
    //   }
    // },

    async fetchData() {
      try {
        if (!this.filter.date_range) {
          return;
        }
        this.setLoading(true);

        //const user_id =  this.users[this.filter.user].CreatedBy;
        const period = this.filter.date_range;
        console.log("period -> ", period);
        let project_id = 0;
        let subProject_id = 0;
        let projectParam = null;
        console.log(
          "filter before assigning -> ",
          this.filter.project,
          (projectParam = this.projects.filter(
            (p) => p.projectID === this.filter.project
          )[0])
        );
        try {
          project_id = this.filter.project || 0;
          console.log("project_id -> ", project_id);
          projectParam = this.projects.filter(
            (p) => p.projectID === this.filter.project
          )[0];
          console.log("projectParam -> ", projectParam);
        } catch (err) {
          project_id = 0;
        }

        try {
          subProject_id = this.filter.subProject;
          if (subProject_id === undefined || subProject_id === null) {
            subProject_id = 0;
          }
        } catch (err) {
          // console.log(err);
          subProject_id = 0;
        }

        let user_id = 0;
        try {
        //user_id = this.filter.user;
         user_id = this.users[this.filter.user].CreatedBy;
          console.log("user_id -> ", user_id);
        } catch (err) {
          user_id = 0;
        }

        console.log(user_id);
        console.log(`Fetching data for User ID: ${user_id}`);

        //console.log(project)
        const projectData = await this.$axios.get(`/admintimesheets`, {
          params: {
            period,
            project_id,
            user_id,
            projectParam,
            subProject_id
          }
        });

        const expenseData = await this.$axios.get(`/expensesAdmin`, {
          params: {
            period,
            project_id,
            user_id,
            projectParam
          }
        });

        console.log("expenseData...",expenseData)

        this.timesheets = projectData.data.map((d) =>
          TimeSheetData.fromApi({
            ...d,
            user: this.currentUser.username
          })
         
        );
        // console.log(
        //   this.timesheets,
        //   this.projects,
        //   "this is filter  ",
        //   this.filter,
        //   "this is filter project",
        //   this.filter.project
        // );
        // console.log(this.timesheets[1].name)
        // console.log(this.timesheets[1].createdBy)
        // console.log(expenseData)
        this.expenses = expenseData.data.map((e) =>
          ExpenseData.fromApi({
            ...e,
            user: this.currentUser.username
          })
        );
        console.log("this expense",this.expenses);
        console.log("this timesheets",this.timesheets);
        // console.log(this.expenses[0].projectCurrency)
        // console.log(this.expenses[0].currency)
      } catch (err) {
        this.makeToast("danger", extractError(err));
      } finally {
        this.setLoading(false);
      }
    },

    // async fetchData2() {
    //   try {
    //     if (isNaN(this.filter.user) || !this.filter.date_range) {
    //       return;
    //     }
    //     this.setLoading(true);

    //     const user_id = this.users[this.filter.user].CreatedBy;
    //     const period = this.filter.date_range;

    //     const projectData = await this.$axios.get(`/timesheets`, {
    //       params: {
    //         period,
    //         user_id
    //       }
    //     });

    //     const expenseData = await this.$axios.get(`/expenses`, {
    //       params: {
    //         period,
    //         user_id
    //       }
    //     });
    //    console.log(projectData)
    //     this.timesheets = projectData.data.map((d) =>
    //       TimeSheetData.fromApi({
    //         ...d,
    //         user: this.currentUser.username
    //       })
    //     );

    //     this.expenses = expenseData.data.map((e) =>
    //       ExpenseData.fromApi({
    //         ...e,
    //         user: this.currentUser.username
    //       })
    //     );
    //   } catch (err) {
    //     this.makeToast("danger", extractError(err));
    //   } finally {
    //     this.setLoading(false);
    //   }
    // },
    editTime(record) {
      // console.log("rhgfjgyurewgfjyrejuh");
      const activityDate = record.activityDate.substring(
        0,
        record.activityDate.indexOf("T")
      ); // Extract the characters before the "T" character
      this.selectedRecord = Object.assign({}, record, { activityDate });
      console.log(this.selectedRecord);
      this.$bvModal.show("edit-time");
    },

    // changeProject: function () {
    //   let selectProject = document.getElementById("fieldsetProject");
    //   let textProject = selectProject.options[selectProject.selectedIndex].text;
    //   let project_id = selectProject.options[selectProject.selectedIndex].value;

    //   if (project_id == 33) {
    //     CommonReportBtn;
    //     document.getElementById("CommonReportBtn").style.display = "none";
    //     document.getElementById("SavannahMainReportBtn").style.display =
    //       "inline";
    //     document.getElementById("SavannahTitleReportBtn").style.display =
    //       "inline";
    //   } else {
    //     document.getElementById("CommonReportBtn").style.display = "inline";
    //     document.getElementById("SavannahMainReportBtn").style.display = "none";
    //     document.getElementById("SavannahTitleReportBtn").style.display =
    //       "none";
    //   }
    // },
    updateTime() {
      let user = JSON.parse(localStorage.getItem("user"));
      let timeSheet = this.timesheets.find(
        (t) => t.id === this.selectedRecord.id
      );
      let data = this.$_.pick(this.selectedRecord, [
        "projectId",
        "subProjectId",
        "activityTime",
        "activityComment",
        "activityDate",
        "activity"
      ]);
      data.previousData = timeSheet;
      if (data.projectId !== 45) {
        data.subProjectId = 0;
      }
      data.user = user;
      this.setLoading(true);
      this.$bvModal.hide("edit-time");
      this.$axios
        .put(`/timesheets/${this.selectedRecord.id}`, data)
        .then(() => this.fetchData())
        .catch((err) => console.error(err))
        .finally(() => {
          this.setLoading(false);
          this.selectedRecord = {};
        });
    },
    editExpense(record) {
      const expenseDate = record.expenseDate.substring(
        0,
        record.expenseDate.indexOf("T")
      ); // Extract the characters before the "T" character
      this.selectedRecord = Object.assign({}, record, { expenseDate });
      console.log(this.selectedRecord.isBillable);
      // console.log(this.projects)
      // console.log(this.expenseTypes)
      this.$bvModal.show("edit-expense");
    },
    updateExpense() {
      let data = this.$_.pick(this.selectedRecord, [
        "amount",
        "comment",
        "categoryId",
        "expenseDate",
        "currencyRate"
      ]);
      data["billable"] = 0;
      let billable = 0;
      if (this.$refs.billableCheck.checked) {
        data["billable"] = 1;
      }
      this.setLoading(true);
      this.$bvModal.hide("edit-expense");
      this.$axios
        .put(`/expenses/${this.selectedRecord.id}`, data, billable)
        .then(() => this.fetchData())
        .catch((err) => console.error(err))
        .finally(() => {
          this.setLoading(false);
        });
    },
    async loadProjects() {
      try {
        const res = await this.$axios.get(
          `/activeprojects?sort_by=ProjectName`
        );
        if (res && res.data.length > 0) {
          this.projects = res.data;
          this.project = res.data[0];
          // this.projects = this.projects.filter((project) =>
          //   project.ProjectOwnerName.toLowerCase().includes(
          //     this.currentUser.userType.toLowerCase()
          //   )
          // );
        }
      } catch (err) {
        console.error(err);
      }
    },
    async loadExpenseTypes() {
      try {
        const expenseTypes = await this.$axios.get(`/expenses/types`);
        this.expenseTypes = expenseTypes.data;
      } catch (err) {
        console.error(err);
      }
    }
  },

  watch: {
    "selectedRecord.expenseDate": "handleExpenseDateChange",
    "filter.date_range": {
      handler(newVal, oldVal) {
        this.fetchData();
        // if (newVal !== oldVal) {
        //   console.log("rejhhrehj")
        //   if (this.filter.project) {
        //     this.fetchData();
        //   } else if (this.filter.user) {
        //     this.fetchData();
        //   }
        // }
      }
    },
    "filter.project": {
      handler(_new, _old) {
        console.log("new project id",_new);
        console.log("old project id",_old);
        this.validateProject = false;
        console.log("here");
        if (_new !== null) {
          this.resetPeriods(_new);
        }
        this.fetchData();
      }
    },

    "filter.subProject": {
      handler(_new, _old) {
        console.log(_new);
        this.fetchData();
      }
    },

    "filter.user": {
      handler(_new, _old) {
        if (_new !== null) {
          this.resetPeriods(_new);
        }
        this.fetchData();
      }
    },
    "filter.owner": {
      handler(_new, _old) {
        if (_new !== null) {
          this.resetPeriods(_new);
        }
        else{
          this.filter.user = null;
        }
        this.fetchData();
      }
    },
    expenses() {
      this.filterProjects();
    },
    timesheets() {
      this.filterProjects();
    }
  }
};
</script>
<style>
label {
  font-size: 18px;
}
h1 {
  font-size: 1.5rem !important;
  margin: 0;
}
/* .card-body {
  padding-top: 0 !important;
} */
hr {
  margin-top: 0;
}
.card {
  margin: 0;
}
.fixed-header {
  position: sticky;
  top: 0;
  z-index: 1;
  font-size: 12px;
}

.timeSheetcard {
  font-size: 13px;
}

@media (min-width: 1700px) {
  /* Add your styles for screens above 1700 pixels here */
  .timeSheetcard {
    font-size: 15px;
    width: 58vw;
  }

  .fixed-header {
    font-size: 15px;
  }
}
</style>

<style scoped>
.modal-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#container {
  width: 80vw;
  height: 47vw;
  border: 2.5px solid black;
}

@media only screen and (max-width: 1700px) {
  #container {
    width: 75vw;
    height: 42vw;
  }
}
</style>
