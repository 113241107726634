<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">
      <!--<button class="btn btn-primary" id="menu-toggle">Toggle Menu</button>

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>-->
      <div
        class="collapse navbar-collapse"
        id="navbarSupportedContent"
        v-if="currentUser && currentUser !== null"
      >
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown no-caret right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <b-avatar :text="userInitial" v-b-tooltip.hover.top.left :title="currentUser.username"></b-avatar>
            </template>
            <b-dropdown-item href="/user-help">Need Help?</b-dropdown-item>
            <b-dropdown-item @click="$emit('logOut')">Logout</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </div>
    </nav>
    <!--<router-link to="/" class="brand">Insight AWP</router-link>
    <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">
      <router-link :to="{ name: 'event-list' }">List</router-link>|
      <router-link :to="{ name: 'event-create' }">Create</router-link>
    </nav>-->
  </div>
</template>

<script>
export default {
  props: ["currentUser"],
  // computed properties
  computed: {
    // Returns the Initials of the user
    userInitial() {
      let name = this.currentUser.name || null;
      return (name && name.substr(0, 1).toUpperCase()) || null;
    }
  }
};
</script>

<style scoped>
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}
.nav > .brand {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.5em;
  color: #39b982;
  text-decoration: none;
}

.nav .nav-item {
  box-sizing: border-box;
  margin: 0 5px;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
}
.nav .nav-item.router-link-exact-active {
  color: #39b982;
  border-bottom: solid 2px #39b982;
}
</style>
